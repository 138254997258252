import { AnyAction } from 'redux';

import { Action, AsyncAction } from '../../../types/actions';
import { GetEvents, GetEventsFullList } from '../../../connection/events';
import { GetEventGroups, CreateEventGroup, AddEventsToEventGroup, UpdateEventGroup, DeleteEventGroup, FetchEventGroupTree } from '../../../connection/event-groups';
import { BrandliveEvent, EventListSortType } from '../../../types/working-model';

export const GET_EVENTS = 'GET_EVENTS';
export function getEvents(
	channel: number,
	token: string,
	limit?: number,
	offset?: number,
	sort?: EventListSortType,
	search?: string,
	selectedEventGroupUuid?: string,
	time?: string,
	selectedEventType?: string
): AnyAction {
	return {
		type: GET_EVENTS,
		promise: GetEvents(channel, token, limit, offset, sort, search, selectedEventGroupUuid, time, false, selectedEventType)
	};
}

export const GET_EVENTS_LIST = "GET_EVENTS_LIST";
export function getEventsList(channel: number, token: string): AsyncAction {
	return {
		type: GET_EVENTS_LIST,
		promise: GetEventsFullList(channel, token)
	};
}

export const ADD_ADMIN_EVENT = 'ADD_ADMIN_EVENT';
export function addAdminEvent(event: BrandliveEvent): Action {
	return {
		type: ADD_ADMIN_EVENT,
		payload: event
	};
}

export const UPDATE_ADMIN_EVENT = 'UPDATE_ADMIN_EVENT';
export function updateAdminEvents(event: BrandliveEvent): Action {
	return {
		type: UPDATE_ADMIN_EVENT,
		payload: event
	};
}


export const UPDATE_EVENTS_NAME = 'UPDATE_EVENTS_NAME';
export function updateEventsName(event: BrandliveEvent): Action {
	return {
		type: UPDATE_EVENTS_NAME,
		payload: event
	};
}

export const CLEAR_EVENTS = 'CLEAR_EVENTS';
export function clearEvents(): Action {
	return {
		type: CLEAR_EVENTS,
		payload: null
	};
}

export const REMOVE_SESSION_FROM_EVENT = 'REMOVE_SESSION_FROM_EVENT';
export function removeSessionFromEventsReducer(sessionID: number, eventUuid: string): Action {
	return {
		type: REMOVE_SESSION_FROM_EVENT,
		payload: { sessionID, eventUuid },
	};
}

export const REMOVE_EVENT_FROM_EVENTS = 'REMOVE_EVENT_FROM_EVENTS';
export function removeEventFromEventsReducer(eventUuid: string): Action {
	return {
		type: REMOVE_EVENT_FROM_EVENTS,
		payload: eventUuid,
	};
}

export const SET_EVENTS_LOADING = 'SET_EVENTS_LOADING';
export function setEventsLoading(isLoading: boolean): Action {
	return {
		type: SET_EVENTS_LOADING,
		payload: isLoading
	};
}

export const GET_EVENT_GROUPS = 'GET_EVENT_GROUPS';
export function getEventGroups(token: string, channel: number): AsyncAction {
	return {
		type: GET_EVENT_GROUPS,
		promise: GetEventGroups(token, channel)
	};
}

export const CREATE_EVENT_GROUP = 'CREATE_EVENT_GROUP';
export function createEventGroup(token: string, channel: number, name: string, eventUuids: string[], adminIds: number[]): AsyncAction {
	return {
		type: CREATE_EVENT_GROUP,
		promise: CreateEventGroup(token, channel, name, eventUuids, adminIds)
	};
}

export const ADD_EVENTS_TO_EVENT_GROUP = 'ADD_EVENTS_TO_EVENT_GROUP';
export function addEventsToEventGroup(token: string, channel: number, groupUuid: string, eventUuids: string[]): AsyncAction {
	return {
		type: ADD_EVENTS_TO_EVENT_GROUP,
		promise: AddEventsToEventGroup(token, channel, groupUuid, eventUuids)
	};
}

export const UPDATE_EVENT_GROUP = 'UPDATE_EVENT_GROUP';
export function updateEventGroup(token: string, channel: number, groupUuid: string, name: string, eventUuids: string[], adminIds: number[]): AsyncAction {
	return {
		type: UPDATE_EVENT_GROUP,
		promise: UpdateEventGroup(token, channel, groupUuid, name, eventUuids, adminIds)
	};
}

export const DELETE_EVENT_GROUP = 'DELETE_EVENT_GROUP';
export function deleteEventGroup(token: string, channel: number, groupUuid: string): AsyncAction {
	return {
		type: DELETE_EVENT_GROUP,
		promise: DeleteEventGroup(token, channel, groupUuid)
	};
}

export const REMOVE_EVENT_FROM_EVENT_GROUPS = 'REMOVE_EVENT_FROM_EVENT_GROUPS';
export function removeEventFromEventGroups(eventUuid: string): Action {
	return {
		type: REMOVE_EVENT_FROM_EVENT_GROUPS,
		payload: eventUuid
	};
}

export const SET_SELECTED_EVENT_GROUP = 'SET_CURRENT_EVENT_GROUP';
export function setSelectedEventGroup(eventUuid: string): Action {
	return {
		type: SET_SELECTED_EVENT_GROUP,
		payload: eventUuid
	};
}

export const CLEAR_GROUP_ERROR = 'CLEAR_GROUP_ERROR';
export function clearGroupError(): Action {
	return {
		type: CLEAR_GROUP_ERROR,
		payload: null
	};
}

export const UPDATE_SELECTED_EVENT_SETTINGS_IN_LIST = "UPDATE_SELECTED_EVENT_SETTINGS_IN_LIST";
export function updateSelectedEventSettingsInList(event: number, settings: BrandliveEvent['settings']): Action {
	return {
		type: UPDATE_SELECTED_EVENT_SETTINGS_IN_LIST,
		payload: { event, settings }
	};
}

export const FETCH_EVENT_GROUP_TREE = "FETCH_EVENT_GROUP_TREE";
export function fetchEventGroupTree(channel: number, token: string) {
	return {
		type: FETCH_EVENT_GROUP_TREE,
		promise: FetchEventGroupTree(token, channel)
	};
}
