import { useEffect } from "react";
import WaitingIndicator from "@general-ui/waiting-indicator/waiting-indicator";
import { useAppDispatch, useTypedSelector } from "store/reducers/use-typed-selector";
import { channelAccessRevoked, getAdminChannels, setAuthTokens, signOut } from "store/actions/authentication";
import { SocketMessage, useSocket } from "connection/socket";
import blAuth from "connection/bl-auth/bl-auth";
import { Redirect } from "react-router-dom";

const style: React.CSSProperties = {
	position: 'fixed',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
};

type AuthGateProps = {
	children: JSX.Element | JSX.Element[];
};
const AuthGate: React.FC<AuthGateProps> = ({ children }) => {
	const dispatch = useAppDispatch();
	const isSignedIn = useTypedSelector(state => state.AuthReducer.signedIn);
	const signingIn = useTypedSelector(state => state.AuthReducer.exchangingRefreshToken);
	const signInError = useTypedSelector(state => state.AuthReducer.exchangeRefreshTokenError);
	const isEnabled = useTypedSelector(state => state.AuthReducer.usingUnifiedAuth);
	const user = useTypedSelector(state => state.AuthReducer.user);
	const socket = useSocket(`user-${user?.id}-${user?.email}`);

	useEffect(() => {
		const handleInvalidate = () => {
			dispatch(signOut());
		};

		const handleRemovedFromChannel = async ({ data }: SocketMessage) => {
			console.warn('Removed from channel', data);

			// remove channel from user's list of available channels
			dispatch(channelAccessRevoked(data.channel));

			if (user && user.active_channel === data.channel) {

				// if they are currently signed in to this channel, force navigation back to root
				window.location.href = window.location.origin;

			} else if (blAuth.refreshToken) {
				// if they are otherwise signed in, force them to get a new access token
				await blAuth.refresh();
				if (blAuth.idToken && blAuth.refreshToken) {
					dispatch(setAuthTokens(
						blAuth.idToken,
						blAuth.refreshToken,
						blAuth.accessToken
					));
					dispatch(getAdminChannels(blAuth.idToken));
				}
			}
		};

		if (socket && user) {
			socket.addListener('invalidate_sessions', handleInvalidate);
			socket.addListener('channel_removed', handleRemovedFromChannel);
		}

		return () => {
			socket.removeListener('invalidate_sessions', handleInvalidate);
			socket.removeListener('channel_removed', handleRemovedFromChannel);
		};
	}, [dispatch, socket, user]);

	useEffect(() => {
		(async () => {
			try {
				if (isEnabled) {
					await blAuth.handleAuthRedirect();

					if (blAuth.refreshToken) {
						await blAuth.refresh();
					} else {
						blAuth.redirectToSignIn({ redirect_uri: `${window.location.origin}/sso-redirect` });
					}

					if (blAuth.idToken && blAuth.refreshToken) {
						dispatch(setAuthTokens(
							blAuth.idToken,
							blAuth.refreshToken,
							blAuth.accessToken
						));
					} else {
						blAuth.redirectToSignIn({ redirect_uri: `${window.location.origin}/sso-redirect` });
					}
				}
			} catch (e) {
				console.error(e);
			}
		})();
	}, [isSignedIn, dispatch, isEnabled]);

	useEffect(() => {
		if (signingIn) return;

		if (signInError) {
			console.error(signInError);
		}
	}, [signingIn, signInError]);

	if (!isEnabled && !user) {
		return (
			<Redirect
				to={{
					pathname: "/authentication/sign-in",
					state: { referrer: window.location.pathname }
				}}
			/>
		);
	}

	if (!isEnabled) {
		return <>{children}</>;
	}

	if (isSignedIn) {
		return <>{children}</>;
	}

	return (
		<div
			id="auth-gate"
			style={style}
		>
			<WaitingIndicator />
		</div>
	);
};

export default AuthGate;