import { useCallback, useEffect, useRef, useState } from 'react';

import { getQueryParams } from "../../utils/utils";
import { getStorageItem, getStorageObject, removeStorageItem, setStorageItem, setStorageObject } from '../../utils/local-storage';
import WaitingIndicator from '../general-ui/waiting-indicator/waiting-indicator';
import Alerts from '../general-ui/alert/alert';
import { SSOAdminAuth, SelectChannel } from '../../connection/auth';
import { useAppDispatch, useTypedSelector } from '../../store/reducers/use-typed-selector';
import { setChangingChannels, setUser } from '../../store/actions/authentication';
import { setSelectedEventGroup } from '../../store/actions/admin/events';
import { jwtDecode } from '../../utils/utils';
import { checkUserIsPresenterOnly } from '../admin/authentication/auth.hooks';
import { BLAdmin } from '../../types/working-model';
import { setSessionStorageObject } from 'utils/session-storage';
import { FetchError } from 'connection/helpers';
import { useHistory } from 'react-router-dom';
import blAuth from 'connection/bl-auth/bl-auth';

const SSORedirect: React.FC = () => {
	const queryParams = getQueryParams(location.search);
	const authCode = queryParams?.code;
	const state = queryParams?.state;
	const dispatch = useAppDispatch();
	const signedIn = useTypedSelector(state => state.AuthReducer.signedIn);
	const urlParams = useRef(new URLSearchParams(window.location.search));
	const history = useHistory();
	const [error, setError] = useState<string | null>(null);

	const handleEventSso = useCallback((ssoEventUrlString: string) => {
		let code = authCode;

		if (state && state !== getStorageItem('sso_state')) { // Validate State
			console.warn('State is not correct');
			code = null;
		}

		removeStorageItem('sso_event_url'); // immediately remove so going to different events does not cause problems
		removeStorageItem(`sso_state`);
		let ssoEventUrl;

		try {
			ssoEventUrl = new URL(ssoEventUrlString);
		} catch (e) {
			console.error(`Invalid sso_event_url: ${ssoEventUrl}`);
			code = null; // Don't try to forward to an invalid URL
		}

		// If no code, stay so the error can be captured while testing 
		if (code && ssoEventUrl instanceof URL) {
			ssoEventUrl.searchParams.append('code', code);
			window.location.href = ssoEventUrl.href;
		}
	}, [authCode, state]);

	const handleAdminSso = useCallback((ssoAdminAuth: { oauth2?: true, client_id?: string, channel?: number, domain?: string }) => {
		const code = authCode;
		const {
			channel,
			oauth2,
			client_id,
			domain
		} = ssoAdminAuth;

		const payload = (() => {
			if (oauth2) {
				if (channel) {
					return {
						channel,
						auth_code: code,
						oauth2
					};
				} else if (domain) {
					return {
						domain,
						auth_code: code,
						oauth2
					};
				}
			} else if (client_id) {
				if (channel) {
					return {
						user_pool_client_id: client_id,
						auth_code: code,
						channel
					};
				} else if (domain) {
					return {
						user_pool_client_id: client_id,
						auth_code: code,
						domain
					};
				}
			}
		})();

		if (code && (channel || domain) && payload) {
			SSOAdminAuth(payload)
				.then(async (result) => {
					if (result.token) {
						const user = jwtDecode(result.token) as BLAdmin;
						const userIsPresenterOnly = checkUserIsPresenterOnly(user.channels, channel);

						// When presenter flow is enabled, we need to redirect to presenter handler
						const oauthFlow = getStorageItem('auth_oauth_flow');
						if (oauthFlow || userIsPresenterOnly) {
							setStorageItem('auth_oauth_sso_token', result.token, .05);
							setStorageItem('forgot_flow_auth_oauth_sso_token', result.token);
							window.location.pathname = '/authentication/presenter/redirect';
							return;
						}

						const authEmail = getStorageItem('auth_email');

						if (authEmail.toLowerCase() !== user.email.toLowerCase()) {
							setSessionStorageObject('auth_mismatch', { requested: authEmail, actual: user.email }, 0.1);
						}

						setStorageItem('currentUser', result.token, 1);

						dispatch(setChangingChannels(true));
						const updatedToken = await SelectChannel(result.token, channel || user.active_channel);
						dispatch(setUser(updatedToken));
						dispatch(setSelectedEventGroup(''));
						dispatch(setChangingChannels(false));

					} else {
						setStorageItem('sso_admin_auth_error', result.reason ?? "unknown error", 0.1);
					}

					window.location.pathname = '/admin';
				})
				.catch(e => {
					let error = "An unknown error has occurred";

					if (e instanceof FetchError) {
						error = e.error;

						const email = getStorageItem('auth_email');

						if (error === 'no channel access') {
							error = `The email ${email ? ("`" + email + "`") : "used"} does not have access to any channels. Please contact your channel administrator for assistance.`;
						}

						setStorageItem('sso_admin_auth_error', e.error, 0.1);
					}

					console.error(e);

					// redirect to admin auth form with error message
					const url = new URL(window.location.href);
					url.searchParams.set('sso_error', error);
					url.pathname = "/authentication/sign-in";
					window.location.href = url.href;
				})
				.finally(() => {
					removeStorageItem('sso_admin_channel');
					removeStorageItem('sso_state');
					removeStorageItem('auth_oauth_flow');
				});
		}
	}, [authCode, dispatch]);

	const backToLogin = useCallback(() => {
		setTimeout(() => {
			if (process.env.LOGIN_APP_HOSTNAME) {
				window.location.href = process.env.LOGIN_APP_HOSTNAME;
			}
		}, 4000);
	}, []);

	useEffect(() => {
		const ssoEventUrl = getStorageItem('sso_event_url');
		const ssoAdminAuth = getStorageObject('sso_admin_auth');

		if (ssoEventUrl) {
			handleEventSso(ssoEventUrl);
			return;
		}

		if (ssoAdminAuth) {
			handleAdminSso(ssoAdminAuth);
			return;
		}


		// if the page should have params from new login app
		if (urlParams.current && urlParams.current.get('bl') === '1') {
			blAuth.handleAuthRedirect().then(({ success, error }) => {
				if (success) {
					window.location.href = window.location.origin;
				} else {
					setError(error ?? "An unknown error occurred");
					backToLogin();
				}
			}).catch(e => {
				console.error(e);
				setError("An unknown error occurred");
				backToLogin();
			});

			return;
		}

		setError("No login information provided");
	}, [handleEventSso, handleAdminSso, dispatch, backToLogin]);

	useEffect(() => {
		// user is signed in, go to events page
		if (signedIn) {
			history.replace('/admin/events');
		}
	}, [history, signedIn]);

	return (
		<div className='center-content-view-port'>
			{!error ? (
				<WaitingIndicator />
			) : (
				<div className="flex" style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
					<h2>Sign-in Error</h2>
					<p>{error}</p>
				</div>
			)}
			<Alerts />
		</div>
	);
};

export default SSORedirect;
