import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';

import { EventsState } from '../../store/types';
import { NavMainItems } from '../../types/working-model';
import { getVariablesAndOverrides } from '../../utils/document-head';
import { getTemplateClassName } from 'utils/utils';
import generateSelfContrastingColor from 'utils/colors/generate-self-contrasting-color';
import { mutateV1ColorsToV2Colors } from 'utils/colors/mutate-v1-colors-to-v2-colors';
import useDetermineWhichColorThemeToUse from './hooks/use-determine-which-color-theme-to-use';
import { ThemePack, ColorOptions, ColorValueIndexes, EPaletteModes } from 'types/theme-packs';
import getRelativeContrastColor from 'utils/colors/generate-relative-contrasting-color';
import getDefaultPackColors from 'utils/colors/get-default-pack-colors';
import getColorWithOpacity from 'utils/colors/get-color-with-opacity';
import { isColorLightOrDarkOnBlendedBackground } from 'utils/colors/is-color-light-or-dark';
import chroma from 'chroma-js';
import { getButtonTextColorOnAccentAndBackgroundBlend } from './hooks/color-blends';

interface Props {
	eventBundle: EventsState['LiveEventReducer']['eventBundle'];
	favIcon?: string;
}
export default function LiveEventDocumentHead({ eventBundle }: Props): JSX.Element {
	/**
	 *
	 *
	 * What's with this hot mess you ask?
	 * Well, I'll tell you.
	 *
	 * Google fonts using the @import rule send in a stylesheet, not a .ttf file or something
	 * Custom uploaded fonts use the @font-face rule
	 *
	 * We want to use Google fonts for the licensing benefits, but we also want to let them upload their own fonts
	 * So we have to use a two-hander here. If it's a google font, make an import statement. Otherwise,
	 * make a font-face rule. Easy peasy.
	 *
	 * 
	 * Notable changes - Custom CSS MUST be placed at the bottom of the <body> tag, because with code splitting,
	 * custom css is loaded before the stock CSS files are loaded, meaning they're being overridden. 
	 * Left in this component for consistency, but these could me moved out of this component into a new one.
	 * It was left here as part of a hotfix, but this could be refactored since it's no longer related to the 
	 * document head.
	 */

	const customCSS = useRef<HTMLStyleElement | null>(null);

	useEffect(() => {
		//if there is custom css
		if (eventBundle?.custom_css) {
			//if there is no custom css element, create one and append it to the bottom of the body
			if (!customCSS.current) {
				customCSS.current = document.createElement('style');
				document.body.appendChild(customCSS.current);
			}

			//manually fill the custom css tag from the bottom of the <body> tag
			customCSS.current.innerHTML =
				(eventBundle.custom_css_compiled)
					? eventBundle.custom_css_compiled
					: eventBundle.custom_css;
		}
	}, [
		eventBundle?.custom_css,
		eventBundle?.custom_css_compiled
	]);

	const { theme: colorTheme } = useDetermineWhichColorThemeToUse(eventBundle);

	const defaultPackColors = useMemo(() => getDefaultPackColors(colorTheme), [colorTheme]);

	const colors = mutateV1ColorsToV2Colors(eventBundle?.settings?.design?.colors?.colors);

	const colorsOverrides: ThemePack | undefined = useMemo(() => {
		if (!eventBundle?.settings?.design?.colors) return undefined;
		return { ...eventBundle.settings.design.colors, colors };
	}, [colors, eventBundle?.settings?.design?.colors]);

	const { colorVariables, classOverrides } = getVariablesAndOverrides(eventBundle, colorTheme, colorsOverrides);

	const contrastColorVariables = useMemo(() => {
		// contrast color variables
		// mostly used for auto generated color packs
		return `
			--accentColorContrast: ${generateSelfContrastingColor(colors?.[colorTheme]?.accentColor?.[ColorValueIndexes.hex] || '#000000')};
			--accentColorOnSessionBackgroundAndAccent20PercentBlend: ${getButtonTextColorOnAccentAndBackgroundBlend({ colorTheme, colors, opacity: 0.2, useThemeBlend: false })};
			--accentColorOnThemeBackgroundAndAccent20PercentBlend: ${getButtonTextColorOnAccentAndBackgroundBlend({ colorTheme, colors, opacity: 0.2, useThemeBlend: true })};
			--secondaryAccentColorContrast: ${generateSelfContrastingColor(colors?.[colorTheme]?.secondaryAccentColor?.[ColorValueIndexes.hex] || '#000000')};
			--buttonTextColorContrast: ${generateSelfContrastingColor(colors?.[colorTheme]?.buttonTextColor?.[ColorValueIndexes.hex] || '#000000')};
			--backgroundColorContrast: ${generateSelfContrastingColor(colors?.[colorTheme]?.backgroundColor?.[ColorValueIndexes.hex] || '#000000')};
			--containerColorContrast: ${generateSelfContrastingColor(colors?.[colorTheme]?.containerColor?.[ColorValueIndexes.hex] || '#000000')};
			--headingTextColorContrast: ${generateSelfContrastingColor(colors?.[colorTheme]?.headingTextColor?.[ColorValueIndexes.hex] || '#000000')};
			--bodyTextColorContrast: ${generateSelfContrastingColor(colors?.[colorTheme]?.bodyTextColor?.[ColorValueIndexes.hex] || '#000000')};
			--secondaryBackgroundColorContrast: ${generateSelfContrastingColor(colors?.[colorTheme]?.secondaryBackgroundColor?.[ColorValueIndexes.hex] || '#000000')};
			
			--primaryColorOnBackgroundColor: ${getRelativeContrastColor(colors?.[colorTheme]?.accentColor?.[ColorValueIndexes.hex] || '#000000', colors?.[colorTheme]?.backgroundColor?.[ColorValueIndexes.hex] || '#000000')};
			--headingTextOnContainerColor: ${getRelativeContrastColor(colors?.[colorTheme]?.headingTextColor?.[ColorValueIndexes.hex] || '#000000', colors?.[colorTheme]?.containerColor?.[ColorValueIndexes.hex] || '#000000')};
			--primaryColorOnContainerColor: ${getRelativeContrastColor(colors?.[colorTheme]?.accentColor?.[ColorValueIndexes.hex] || '#000000', colors?.[colorTheme]?.containerColor?.[ColorValueIndexes.hex] || '#000000')};
			--bodyTextOnContainerColor: ${getRelativeContrastColor(colors?.[colorTheme]?.bodyTextColor?.[ColorValueIndexes.hex] || '#000000', colors?.[colorTheme]?.containerColor?.[ColorValueIndexes.hex] || '#000000')};
			--headingTextOnBannerColor: ${getRelativeContrastColor(colors?.[colorTheme]?.headingTextColor?.[ColorValueIndexes.hex] || '#000000', colors?.[colorTheme]?.secondaryBackgroundColor?.[ColorValueIndexes.hex] || '#000000')};
			--bodyTextOnBannerColor: ${getRelativeContrastColor(colors?.[colorTheme]?.bodyTextColor?.[ColorValueIndexes.hex] || '#000000', colors?.[colorTheme]?.secondaryBackgroundColor?.[ColorValueIndexes.hex] || '#000000')};
			--labelOnBannerColor: ${getRelativeContrastColor(colors?.[colorTheme]?.secondaryAccentColor?.[ColorValueIndexes.hex] || '#000000', colors?.[colorTheme]?.secondaryBackgroundColor?.[ColorValueIndexes.hex] || '#000000')};
			--labelOnBannerBackground: ${getRelativeContrastColor(colors?.[colorTheme]?.secondaryBackgroundColor?.[ColorValueIndexes.hex] || '#000000', colors?.[colorTheme]?.secondaryAccentColor?.[ColorValueIndexes.hex] || '#000000')};
		`;
	}, [colors, colorTheme]);

	const leaderboardStyles = eventBundle?.settings?.leaderboard_settings?.styles;
	const leaderboardNavIconEnabled = eventBundle?.homepage?.event_main_nav_items?.find(navItem => {
		if (navItem.name === NavMainItems.Leaderboard && navItem.is_on) {
			return true;
		}
		return false;
	});
	const leaderboardEnabled = eventBundle?.settings?.leaderboard_settings?.enabled && leaderboardNavIconEnabled;

	const recaptchaEnabled = !!eventBundle?.registration_settings?.enableRecaptcha;

	const recaptchaScript = useMemo(() => {
		if (recaptchaEnabled) {
			return (
				<script
					src={`https://www.recaptcha.net/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_CLIENT_SITE_KEY}`}
					async
					defer
				></script>
			);
		}
		return;
	}, [recaptchaEnabled]);

	const palleteCreationType = useMemo(() => {
		// if color pack channel is zero, then it's auto, else do the rest
		if (eventBundle?.settings?.design?.colors?.channel === 0) {
			return 'manual';
		}
		return eventBundle?.settings?.design?.colors?.creation_type || 'manual';
	}, [eventBundle]);

	const headingFontUrl = useMemo(() => {
		return eventBundle?.settings?.design?.colors?.font_pack?.heading_url
			|| eventBundle?.settings?.design?.font?.heading_url;
	}, [eventBundle?.settings?.design]);
	const headingFontName = useMemo(() => {
		return eventBundle?.settings?.design?.colors?.font_pack?.heading_name
			|| eventBundle?.settings?.design?.font?.heading_name;
	}, [eventBundle?.settings?.design]);

	const bodyFontUrl = useMemo(() => {
		return eventBundle?.settings?.design?.colors?.font_pack?.body_url
			|| eventBundle?.settings?.design?.font?.body_url;
	}, [eventBundle?.settings?.design]);
	const bodyFontName = useMemo(() => {
		return eventBundle?.settings?.design?.colors?.font_pack?.body_name
			|| eventBundle?.settings?.design?.font?.body_name;
	}, [eventBundle?.settings?.design]);
	const customStyles = useMemo(() => {
		return eventBundle?.settings?.design?.colors?.custom_styles || eventBundle?.settings?.design?.custom;
	}, [eventBundle?.settings?.design]);

	return (
		<Helmet>
			{/* add data-theme attribute to body tag */}
			<body
				data-template={getTemplateClassName(eventBundle?.template.name)}
				data-theme-default-base={!eventBundle?.settings?.design?.colors?.channel ? "true" : "false"}
				data-color-mode={colorTheme}
				data-theme-pack-type={palleteCreationType}
			/>


			{recaptchaScript}
			<script type="text/javascript" src="https://www.gstatic.com/meetjs/meet.js" />
			<style>
				{`

					${!headingFontUrl || !bodyFontUrl
						? `
								@import url('https://assets.brandlive.com/assets/fonts/replacement/familyOpenSans300300i400400i600600i700700i800800i.css');
								@import url('https://assets.brandlive.com/assets/fonts/replacement/familyRobotoitalwght010003000400050007000900110013001400150017001900displayswap.css');
							`
						: ''}

					${headingFontUrl
						? `${headingFontUrl.includes('fonts.googleapis.com')
							|| headingFontUrl.includes('assets.brandlive.com')
							? `	@import url(${headingFontUrl});`
							: `@font-face {
									font-family: "${headingFontName}";
									src: url(${headingFontUrl});
								}`
						}`
						: `.editor {
								--headingFont: 'Poppins', sans-serif;
							}`
					}
					
					${bodyFontUrl
						? `${bodyFontUrl?.includes('fonts.googleapis.com')
							|| bodyFontUrl?.includes('assets.brandlive.com')
							? `@import url(${bodyFontUrl});`
							: `@font-face {
									font-family: "${bodyFontName}";
									src: url(${bodyFontUrl});
								}`
						}`
						: `.editor {
								--bodyFont: 'Poppins', sans-serif;
							}`
					}
					
					:root {
							/* Custom input styling --customInputBorderRadius, --customButtonBorderRadius, --customContainerBorderRadius --customLabelBorderRadius usage: */
							/* border-radius: var(--customInputBorderRadius, 12px); */
							/* Where 12px (or the appropriate value for the specific component) would be the fallback theme default value */
							${customStyles?.input?.use_custom && customStyles?.input?.css?.borderRadius ? `
								--customInputBorderRadius: ${customStyles.input.css.borderRadius};
								${customStyles?.input?.css?.borderRadius === '100px' ? `
									--textAreaLeftPadding: 50px;
								` : ''}
							`
						: ''}
							${customStyles?.button?.use_custom && customStyles?.button?.css?.borderRadius ?
						`--customButtonBorderRadius: ${customStyles.button.css.borderRadius};`
						: ''}
							${customStyles?.container?.use_custom && customStyles?.container?.css?.borderRadius ?
						`--customContainerBorderRadius: ${customStyles.container.css.borderRadius};`
						: ''}
							${customStyles?.img?.use_custom && customStyles?.img?.css?.borderRadius ?
						`--customImageBorderRadius: ${customStyles.img.css.borderRadius};`
						: ''}
							${customStyles?.label?.use_custom && customStyles?.label?.css?.borderRadius ?
						`--customLabelBorderRadius: ${customStyles.label.css.borderRadius};`
						: ''}
							
						--headingFont: "${headingFontName}", sans-serif;
						--bodyFont: "${bodyFontName}", sans-serif;

						// these are for editor overrides, because the --headingFont and --bodyFont variables
						// are overwritten by the event font, which causes issues in global components like modals
						--editorHeadingFont: 'Poppins', sans-serif;
						--editorBodyFont: 'Poppins', sans-serif; 
						
						${colorVariables}
						${defaultPackColors}
						${contrastColorVariables}
					}
					
					${classOverrides}
				`}
			</style>

			{eventBundle?.template?.css ? (
				<style>
					{eventBundle.template.css}
				</style>
			) : null}

			{leaderboardStyles?.custom_css && leaderboardEnabled && (
				<style>
					{
						leaderboardStyles.compiled_css
							? leaderboardStyles.compiled_css
							: leaderboardStyles.custom_css
					}
				</style>
			)}
		</Helmet>
	);
}
