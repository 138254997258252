import './i18n/config';

import { Suspense, lazy, useEffect, useCallback, useState } from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";

// WARNING!!! Must be above Admin (and in fact any other component) otherwise it overrides styles of components
// WARNING!!! Broke the Landing page because the same components were loaded in Home.tsx and styles.scss overrode the Landing page
// WARNING!!! https://stackoverflow.com/a/63308877
import './styles.scss';
import 'react-virtualized/styles.css';

import Home from './components/root/home';
import { getAdminChannels, refreshToken } from './store/actions/authentication';
import MonitorUserTime from './utils/session-timer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LiveEvent from './components/live-event/live-event';
import Uploader from './components/general-ui/uploader/uploader';
import WaitingIndicator from './components/general-ui/waiting-indicator/waiting-indicator';
import GoogleAuth from './components/google-auth/google-auth';
import SSORedirect from './components/sso-redirect/sso-redirect';
import PageNotFound from './components/404/404';
import MonitorPageVisibility from './utils/event-activity-timer';
import IconPreviewer from './components/general-ui/icon-previewer';
import { useTypedSelector, useAppDispatch } from './store/reducers/use-typed-selector';
import AppContext, { AppContexts } from 'components/app-context';
import { ThemeContext } from 'components/live-event/theme-context';
import { EPaletteModes } from 'types/theme-packs';
import AuthGate from 'components/auth-gate/auth-gate';
import { useForceSecureCookies } from 'hooks/cookie.hooks';
import FeatureFlagGate from 'components/feature-flag-gate/feature-flag-gate';
import useMigrateColorPackToThemePack from 'use-migrate-color-pack-to-theme-packs.';

// import a worker by creating a blob from the text of it
// const testWorker = new Worker(new URL('./workers/test-worker.ts', import.meta.url));

const Admin = lazy(() => import('./components/admin/admin'));
const Authentication = lazy(() => import('./components/admin/authentication/authentication'));
const Moderator = lazy(() => import('./components/moderation/moderation'));
const EmbedChat = lazy(() => import('./components/embed/chat'));

const adminDomainRegex = /^admin\..*/; // Starts with 'admin.'
const googleDomainRegex = /^google-auth\..*/; // Starts with 'google-auth.'
const oauthFlowRegex = /\/authentication\/presenter\/sign-in.*/;
const isTestEditor = window.location.href.startsWith('http://localhost:3000/admin');
const isEditor = adminDomainRegex.test(window.location.hostname);
const isAdmin = (isEditor || window.location.hostname === 'localhost');
const isGoogleAuth = googleDomainRegex.test(window.location.hostname);
const isOauthFlow = oauthFlowRegex.test(window.location.pathname);
const isProd = process.env.REACT_APP_STAGE === 'prod';
const isHot = process.env.REACT_APP_STAGE === 'hotfix';
const isLocal = process.env.REACT_APP_STAGE === 'local';

const isEmbed = window.location.href.includes('embed=true');

if (window.location.hostname.startsWith('www.brandlive')) {
	window.location.href = window.location.href.replace(/www/, 'admin');
}

let featureFlagToolImported = false;
// never import the flag tool if this is not production
if (!isProd && !isHot && !featureFlagToolImported) {
	import('./utils/feature-flag-tool').then(() => {
		console.log('Flag tool imported');
		featureFlagToolImported = true;
	});
}

const App: React.FC = () => {
	const token = useTypedSelector(state => state.AuthReducer.token);
	const [hostname, setHostname] = useState<string | undefined>(isLocal ? undefined : window.location.hostname);

	const [currentTheme, setCurrentTheme] = useState<EPaletteModes>(EPaletteModes.Light);

	const dispatch = useAppDispatch();

	useForceSecureCookies();

	const { updatingEventThemePackMigration } = useMigrateColorPackToThemePack(isAdmin);

	useEffect(() => {
		if (isLocal) {
			import('./utils/mock-hostname').then(({ getMockHostname }: { getMockHostname: () => string }) => {
				setHostname(getMockHostname());
			});
		}
	}, []);

	useEffect(() => {
		//if the user is signed in on startup, refresh their token first thing to catch any changes
		// Do NOT do this in oAuth flow.  oAuth flow uses a different set of tokens to prevent crossing
		// between multiple appliations.
		if (token && !isOauthFlow) {
			dispatch(refreshToken(token));
			dispatch(getAdminChannels(token));
		}

		//checks for user activity - if the user is signed in and has had no activity for the last 1 hour, sign them out. Will redirect them to the sign in form if they're on an admin route
		MonitorUserTime();

		// checks if the page is in the foreground and there is a video playing
		MonitorPageVisibility();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fallback = useCallback(() => (
		<div style={{ position: 'absolute', inset: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#1a1a1f' }}>
			<WaitingIndicator fillSpace />
		</div>
	), []);

	if (updatingEventThemePackMigration) {
		return fallback();
	}
	return hostname ? (
		<Suspense fallback="">
			<div className="App" style={{ pointerEvents: 'auto' }}>
				{
					isGoogleAuth ? (
						<Router>
							<CompatRouter>
								<AppContext.Provider value={AppContexts.googleAuth}>
									<Switch>
										<Route path="*">
											<GoogleAuth />
										</Route>
									</Switch>
								</AppContext.Provider>
							</CompatRouter>
						</Router>
					) : (
						<>
							<Router>
								<CompatRouter>
									{(() => {
										// embed routes
										if (isEmbed) {
											return (
												<AppContext.Provider value={AppContexts.embed}>
													<Switch>
														<Route path="/:event_uuid/:session_uuid/:language">
															<Suspense fallback={fallback()}>
																<EmbedChat />
															</Suspense>
														</Route>
													</Switch>
												</AppContext.Provider>
											);
										}

										// admin routes
										if (isAdmin) {
											return (
												<AppContext.Provider value={AppContexts.admin}>
													<FeatureFlagGate>
														<Switch>
															{isLocal && (
																<Route path="/icon-list">
																	<IconPreviewer />
																</Route>
															)}
															<Route path="/authentication">
																<Suspense fallback={fallback()}>
																	<Authentication />
																</Suspense>
															</Route>
															<Route exact path={'/sso-redirect'}>
																<SSORedirect />
															</Route>
															<Route path="/event/:uuid/:language?">
																<AppContext.Provider value={AppContexts.liveEvent}>
																	<ThemeContext.Provider value={[currentTheme, setCurrentTheme]}>
																		{/** Will need (nginx?) routing method to forward hostname here - routing tbd, but this will work for now */}
																		<LiveEvent isEditor={isEditor} debug={true} />
																	</ThemeContext.Provider>
																</AppContext.Provider>
															</Route>
															<Route path="/google-auth">
																<AppContext.Provider value={AppContexts.googleAuth}>
																	<GoogleAuth />
																</AppContext.Provider>
															</Route>
															<Route path="/admin">
																<AuthGate>
																	<Suspense fallback={fallback()}>
																		<Admin />
																	</Suspense>
																</AuthGate>
															</Route>
															<Route path="/moderator/event/:event_uuid/:lang/session/:session_uuid">
																<AuthGate>
																	<Suspense fallback={fallback()}>
																		<Moderator />
																	</Suspense>
																</AuthGate>
															</Route>
															{/* page-not-found route not used in app but provides path to verify component */}
															<Route path="/page-not-found">
																<PageNotFound />
															</Route>
															<Route path="/">
																<Home />
															</Route>
														</Switch>
													</FeatureFlagGate>
												</AppContext.Provider>
											);
										}

										// live event routes
										return (
											<AppContext.Provider value={AppContexts.liveEvent}>
												<Switch>
													<Route exact path={'/sso-redirect'}>
														<SSORedirect />
													</Route>
													<Route path="/">
														<ThemeContext.Provider value={[currentTheme, setCurrentTheme]}>
															<LiveEvent isEditor={isEditor} host={hostname} eventNamePass={window.location.pathname.split("/")?.[1]} />
														</ThemeContext.Provider>
													</Route>
												</Switch>
											</AppContext.Provider>
										);
									})()}
								</CompatRouter>
							</Router>
						</>
					)
				}
			</div>
			<div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, pointerEvents: 'none', zIndex: 1000 }} id="popover-global-container" className={(!isEditor && !isTestEditor) ? "live-event-fullscreen-popover" : ""} />
			<div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, pointerEvents: 'none' }} id="modal-global-container" className={(!isEditor && !isTestEditor) ? "live-event-modal" : ""} />
			<div id="date-portal" />
			<Uploader />
		</Suspense>
	) : fallback();
};

export default App;
