import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import classNames from 'classnames';

import Icon, { COLORS, ICONS } from "../icon";

import './navigation-dropdown.scss';

interface NavigationDropdownProps {
	children: JSX.Element | JSX.Element[] | React.ReactNode;
	title: string | JSX.Element;
	className?: string;
	navdropClassName?: string;
	buttonClassName?: string;
	isArrow?: boolean
	edge?: 'left' | 'center' | 'right';
	yPosition?: 'top' | 'bottom';
	renderCustomIcon?: () => JSX.Element;
	customAriaLabel?: string;
	setModalOpen?: Dispatch<SetStateAction<boolean>>;
	renderLeftIcon?: () => JSX.Element;
	isAsync?: boolean;
	root?: React.RefObject<HTMLElement>;
	onMenuToggle?: (open: boolean) => void;
	testId?: string;
}
export default function NavigationDropdown({
	children,
	title,
	className,
	isArrow = true,
	buttonClassName = "no-style",
	navdropClassName,
	edge = 'left',
	yPosition = 'bottom',
	renderCustomIcon,
	customAriaLabel,
	setModalOpen,
	renderLeftIcon,
	isAsync = false,
	root,
	onMenuToggle = () => null,
	testId
}: NavigationDropdownProps): JSX.Element {
	const [open, setOpen] = useState(false);
	const [isWaiting, setIsWaiting] = useState(false);
	const [ignoreBottom, setIgnoreBottom] = useState(false);
	const navInternal = useRef<HTMLDivElement>(null);
	const self = useRef<HTMLButtonElement>(null);
	const setModalOpenRef = useRef<Dispatch<SetStateAction<boolean>> | undefined>(setModalOpen);

	useEffect(() => {
		setModalOpenRef.current = setModalOpen;
	}, [setModalOpen]);

	useEffect(() => {
		onMenuToggle?.(open);
	}, [onMenuToggle, open]);

	// on escape, close dropdown
	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.code === 'Escape' || event.key === 'Escape') {
				setOpen(false);
				setModalOpenRef.current?.(false);
			}
		};
		document.addEventListener("keydown", handleKeyDown);
		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, []);

	const handleClose = useCallback((e: any) => {
		const path = e.path || e.composedPath();
		for (const item of path) {
			if (item?.classList?.contains('nav-dropdown-search')) return;
			if (self.current === item) { return; }
		}

		if (!isAsync) {
			setOpen(false);
			setModalOpenRef.current?.(false);
		} else {
			setIsWaiting(true);
		}
	}, [isAsync]);

	useEffect(() => {
		if (open) {
			window.addEventListener('click', handleClose);
		}
		return () => window.removeEventListener('click', handleClose);
	}, [open, handleClose]);

	const handleClick = useCallback((e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();

		setOpen(prev => !prev);
		setModalOpenRef.current?.(prev => !prev);
	}, []);

	useEffect(() => {
		// we only want the nav dropdown to open using the top class IF it's not taller than its container
		// if it is and will be invisible above the scroll container, swap it to bottom
		if (open && navInternal.current && root?.current) {
			const io = new IntersectionObserver((e) => {

				if (e[0]?.boundingClientRect.top < (e[0]?.rootBounds?.top || 0)) {
					setIgnoreBottom(true);
				} else {
					setIgnoreBottom(false);
				}

				io.disconnect();
			}, {
				threshold: 0, // will be considered intersecting if 10% of the view is intersecting with the scroll container
				root: root?.current
			});

			io.observe(navInternal.current);

			return () => {
				io.disconnect();
			};
		}
	}, [open, root]);

	const handleAnimationClosed = () => {
		if (!open) {
			setIgnoreBottom(false);
		}
	};

	return (
		<div data-testid={testId} className={classNames("navigation-dropdown", className, { open })}>
			<button role='button' onClick={handleClick} className={classNames(buttonClassName, { open })} ref={self} aria-label={customAriaLabel ? customAriaLabel : "Add photo"}>
				{renderLeftIcon ? renderLeftIcon() : null}
				<span className="navdrop-title">{title}</span>
				{isArrow && (
					renderCustomIcon ? (
						renderCustomIcon()
					) : (
						<Icon name={ICONS.KEYBOARD_ARROW_DOWN} color={classNames(COLORS.BLACK, "navdrop-arrow")} size={14} />
					)
				)}
			</button>
			<div onTransitionEnd={handleAnimationClosed} ref={navInternal} className={classNames("navdrop-list", navdropClassName,
				{
					right: edge === 'right',
					center: edge === 'center',
					top: ignoreBottom || yPosition === 'top',
					bottom: !ignoreBottom && yPosition === 'bottom',
					'async-nav': isWaiting
				},
			)}>
				{children}
			</div>
		</div>
	);
}