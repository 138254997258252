import { EventThemePackMigration } from "connection/theme-packs";
import { useEffect, useRef, useState } from "react";
import { batch } from "react-redux";

import { loadColorPacks, loadFontPacks, loadWorkingEvent } from "store/actions/admin/create-event";
import { useAppDispatch, useTypedSelector } from "store/reducers/use-typed-selector";

const useMigrateColorPackToThemePack = (isAdmin?: boolean) => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const activeChannel = useTypedSelector(state => state.AuthReducer.user?.active_channel);

	const [updatingEventThemePackMigration, setUpdatingEventThemePackMigration] = useState(false);

	const dispatch = useAppDispatch();

	// !hasAttempted.current is necessary otherwise the event settings page will make this thing run over and over again
	const hasAttempted = useRef(false);

	useEffect(() => {
		const isAuthorizedToMigrate = isAdmin && token && activeChannel;
		const workingEventRequiresMigration = workingEvent && !workingEvent?.settings?.design?.colors;
		const hasNotAttemptedToMigrate = !updatingEventThemePackMigration && !hasAttempted.current;

		if (
			isAuthorizedToMigrate
			&& workingEventRequiresMigration
			&& hasNotAttemptedToMigrate
		) {

			hasAttempted.current = true;

			setUpdatingEventThemePackMigration(true);

			(async () => {
				try {
					await EventThemePackMigration(workingEvent.event, token);
					// refetch event
					batch(() => {
						dispatch(loadWorkingEvent(workingEvent.uuid, token));
						dispatch(loadColorPacks(token, activeChannel));
						dispatch(loadFontPacks(activeChannel, token));
					});
					setUpdatingEventThemePackMigration(false);
				} catch (error) {
					setUpdatingEventThemePackMigration(false);
					console.error('Error migrating theme packs', error);
				}
			})();
		}
		// do not add `updatingEventThemePackMigration` or the hook will trigger multiple times before catching up
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, isAdmin, token, workingEvent, activeChannel]);

	return { updatingEventThemePackMigration };
};

export default useMigrateColorPackToThemePack;
