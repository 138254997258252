import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import Icon, { COLORS, ICONS } from '../icon';

interface CheckboxProps {
	checked: boolean;
	// TODO rename to name
	value: string | number;
	onChange: (e: string | number, isOn: boolean, metadata?: Record<string, string | number>) => void;
	partial?: boolean;
	size?: number;
	selectedIconName?: string;
	unselectedIconName?: string;
	color?: string;
	transparent?: boolean;
	activeColor?: string;
	inactiveColor?: string;
	backgroundColor?: string;
	greyCheckbox?: boolean;
	label?: JSX.Element | string;
	disabled?: boolean;
	labelClassName?: string;
	metadata?: Record<string, string | number>;
}



export default function Checkbox(props: CheckboxProps): JSX.Element {
	const {
		onChange,
		value,
		checked,
		partial,
		size = 20,
		color = "",
		inactiveColor = "",
		activeColor = '',
		transparent = false,
		selectedIconName,
		unselectedIconName,
		backgroundColor = 'transparent',
		greyCheckbox = false,
		label = '',
		disabled,
		labelClassName = '',
		metadata
	} = props;

	const [isFocused, setIsFocused] = useState(false);

	const memoizedChange = useCallback((e?: any) => {
		e?.stopPropagation();
		onChange(value, !checked, metadata);
	}, [checked, onChange, value, metadata]);

	const iconColor = useMemo(() => {
		if (checked) {
			if (activeColor) { return activeColor; }
			if (color) { return color; }
			return COLORS.WHITE;
		} else {
			if (inactiveColor) { return inactiveColor; }
			if (color) { return color; }
			return COLORS.WHITE;
		}
	}, [checked, activeColor, inactiveColor, color]);

	const style = useMemo(() => {
		const style: any = {};
		if (size && !label) {
			style.height = size;
			style.width = size;
		}

		return style;
	}, [size, label]);

	const icon = useMemo(() => {
		if (partial) { return ICONS.CHECKBOX_CHECKED_PARTIAL; }

		if (checked) {
			if (selectedIconName) { return selectedIconName; }
			else { return ICONS.CHECKBOX_CHECKED; }
		} else {
			if (unselectedIconName) { return unselectedIconName; }
			else { return ICONS.CHECKBOX; }
		}
	}, [checked, partial, selectedIconName, unselectedIconName]);

	useEffect(() => {
		const handleKeyPress = (e: KeyboardEvent) => {
			if (disabled) return;
			// if spacebar, prevent scroll
			if (e.key === " " && isFocused) {
				e.preventDefault();
			}
			// if enter or spacebar, trigger click
			if ((e.key === 'Enter' || e.key === " ") && isFocused) {
				memoizedChange();
			}
		};
		document.addEventListener('keypress', handleKeyPress);
		return () => {
			document.removeEventListener('keypress', handleKeyPress);
		};
	}, [isFocused, memoizedChange, disabled]);

	return (
		<div
			className={classNames(
				'field-group checkbox',
				{
					transparent,
					"grey-checkbox": greyCheckbox,
					'has-label': !!label,
					'checkbox-active': checked,
					disabled
				}
			)}
			style={style}
			onClick={memoizedChange}
			tabIndex={disabled ? -1 : 0}
			onFocus={() => {
				if (!disabled) {
					setIsFocused(true);
				}
			}}
			onBlur={() => setIsFocused(false)}
		>
			<Icon size={size} color={iconColor} name={icon} backgroundColor={backgroundColor} />
			{label
				? typeof label === 'string'
					? <span className={labelClassName}>{label}</span>
					: label
				: null}
		</div>
	);
}