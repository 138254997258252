import React, { useEffect, useMemo, useRef, useState, useCallback, useContext } from "react";
import classNames from "classnames";
import FocusTrap from 'focus-trap-react';
import { throttle } from "underscore";

import Icon, { COLORS, ICONS } from "../icon";
import ModalPortal from "./modal-portal";
import Switch from "../switch/switch";
import { FieldLabel } from "../field/field";
import { useTypedSelector } from "../../../store/reducers/use-typed-selector";
import { ThemeContext } from "components/live-event/theme-context";
import { EPaletteModes } from "types/theme-packs";

import './modal.scss';
import "./ev-modal.scss";

interface IModalBodyWrapper {
	useAdditionalWrapper: boolean;
}

const ModalBodyWrapper: React.FC<React.PropsWithChildren<IModalBodyWrapper>> = ({ useAdditionalWrapper, children }) => {
	if (useAdditionalWrapper) {
		return (
			<div className="additional-modal-body-wrapper">
				{children}
			</div>
		);
	}
	return (
		<>
			{children}
		</>
	);
};

export type ModalProps = {
	open: boolean;
	children: any;
	title?: string;
	onRequestClose: () => void;
	header?: any;
	footer?: any;
	cancellable: boolean;
	closeable: boolean;
	size?: 'normal' | 'medium' | 'large' | 'small' | 'desktop' | 'tablet' | 'mobile';
	subtitle?: string;
	closeOnEscape?: boolean;
	className?: string;
	noPadding?: boolean;
	noSidePadding?: boolean;
	hideHeader?: boolean;
	trapFocus?: boolean;
	focusTrapOptions?: { [key: string]: boolean };
	showOverflow?: boolean;
	padTitle?: boolean;
	eventPreview?: boolean;
	scrollBodyToBottom?: boolean; // Added for create breakout rooms session modal
	topRightToggle?: boolean;	// Added for create breakout rooms session modal
	onToggleClicked?: (value: any, on: boolean) => void; // Added for create breakout rooms session modal
	toggleLabel?: string // Added for create breakout rooms session modal
	toggleValue?: boolean // Added for create breakout rooms session modal
	throttleTime?: number;
	onBodyScroll?: (...args: any[]) => void;
	noCloseOnEscape?: boolean;
	closeByX?: boolean;
	useAdditionalWrapper?: boolean;
	addMaxHeightClass?: boolean;
	addModalPadding?: boolean;
	portalClassName?: string;
	onTransitionedClosed?: () => void;
	onTransitionedOpened?: () => void;
	topRightToggleTooltipText?: string;
	/** isAdmin will prevent event font family from overwriting the modal font family */
	isAdmin?: boolean;
};

export default function ModalComponent(props: ModalProps): JSX.Element {
	const {
		addMaxHeightClass = false,
		addModalPadding = false,
		cancellable,
		children,
		className,
		closeable,
		closeByX = false,
		eventPreview,
		focusTrapOptions = { allowOutsideClick: true },
		footer,
		header,
		hideHeader = false,
		noCloseOnEscape = false,
		noPadding = false,
		noSidePadding = false,
		onToggleClicked,
		onBodyScroll,
		open,
		onRequestClose,
		onTransitionedClosed,
		onTransitionedOpened,
		padTitle = true,
		portalClassName = '',
		scrollBodyToBottom,
		size = "normal",
		showOverflow = false,
		subtitle,
		throttleTime = 100,
		title = '',
		trapFocus = true,
		toggleLabel,
		toggleValue = false,
		topRightToggle,
		useAdditionalWrapper = false,
		topRightToggleTooltipText = '',
		// initializing true because the majority of our modals are in admin
		isAdmin = true
	} = props;

	const aDropdownIsOpen = useTypedSelector(state => state.CreateEventReducer.aDropdownIsOpen);
	const [isOpen, setIsOpen] = useState(false);
	const [openClass, setOpenClass] = useState("closed");
	const transitionComplete = useRef(onTransitionedClosed);
	const transitionCompletedOpen = useRef(onTransitionedOpened);
	const close = useRef(onRequestClose);
	const modalBodyRef = useRef<HTMLDivElement>(null);

	const [theme] = useContext(ThemeContext);
	const darkMode = theme === EPaletteModes.Dark;

	useEffect(() => {
		// Added this for the Breakout Rooms modal. When adding new rooms we flip the "scrollBodyToBottom" prop which
		// fires this to auto scroll smoothly to the bottom of the rooms list to bring the new one into view
		if (scrollBodyToBottom !== undefined) {
			setTimeout(() => {
				modalBodyRef.current?.scrollBy({ top: modalBodyRef.current.scrollHeight, behavior: "smooth" });
			}, 100);
		}
	}, [scrollBodyToBottom]);

	useEffect(() => {
		// prevent the overflow from being toggled on or off on every render
		// which causes the scroll bar on the right to blink in and out when the parent state changes
		close.current = onRequestClose;
	}, [onRequestClose]);

	useEffect(() => {
		const escape = (e: any) => {
			if (e.key === 'Escape') {
				close.current();
			}
		};

		if (open) {
			setIsOpen(true);
			setTimeout(() => setOpenClass("open"));
			if (!noCloseOnEscape) {
				window.addEventListener('keyup', escape);
			}
		} else {
			setOpenClass("closed");
		}

		return () => {
			window.removeEventListener('keyup', escape);
			setTimeout(() => {
				document.body.style.overflow = '';
			}, 100);
		};
	}, [open, noCloseOnEscape]);

	const transitionEnded = useCallback(() => {
		if (!open) {
			setIsOpen(false);
			transitionComplete.current?.();
		} else {
			// prevent body scrolling when modal is open
			document.body.style.overflow = 'hidden';
			transitionCompletedOpen.current?.();
		}

	}, [open]);

	useEffect(() => {
		// convert to ref so we don't have to change the transitionEnded callback every render
		transitionComplete.current = onTransitionedClosed;
	}, [onTransitionedClosed]);

	useEffect(() => {
		// convert to ref so we don't have to change the transitionEnded callback every render
		transitionCompletedOpen.current = onTransitionedOpened;
	}, [onTransitionedOpened]);

	const Trap = trapFocus ? FocusTrap : React.Fragment;

	const trapProps = trapFocus ? { focusTrapOptions } : {};

	const PrimaryTooltip = (): JSX.Element => <Icon name={ICONS.PRIMARY_TOOLTIP} color={COLORS.CYAN} size={12}></Icon>;

	const scrollbarVisible = useMemo(() => {
		if (modalBodyRef?.current && modalBodyRef.current.scrollHeight > modalBodyRef.current.clientHeight) {
			return true;
		} else {
			return false;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalBodyRef.current?.scrollHeight, modalBodyRef.current?.clientHeight]);

	const handleBodyScroll = throttle((event: any) => {
		onBodyScroll?.(event?.target);
	}, throttleTime);

	return isOpen ? (
		<ModalPortal className={`${portalClassName} ${isAdmin ? 'isAdmin' : ''}`.trim()}>
			<Trap {...trapProps}>
				<div
					className={classNames(className, "modal-wrapper", openClass, { 'dark-mode': darkMode })}
					onTransitionEnd={transitionEnded}
					style={{ pointerEvents: 'auto' }}
				>
					<div
						onClick={cancellable && !aDropdownIsOpen ? onRequestClose : () => ({})}
						className="modal-background"
					></div>
					<div className={classNames(
						"modal",
						size,
						{ 'no-padding': noPadding },
						{ 'no-side-padding': noSidePadding },
						addModalPadding ? 'modal-all-around-border-padding' : '',
						addMaxHeightClass ? 'max-height-90-percent' : '',
						eventPreview ? 'event-preview' : '',
						isAdmin ? 'isAdmin' : ''
					)}>
						<ModalBodyWrapper useAdditionalWrapper={useAdditionalWrapper}>
							<>
								{hideHeader ? null : (
									<div className={classNames("modal-header", { 'pad-title': padTitle }, closeByX ? "close-by-x" : "")}>
										<div className="modal-title-container">
											<h4 className="modal-title">{title}</h4>
											{closeByX &&
												<div className="closeByXIcon" onClick={onRequestClose}>
													<Icon name={ICONS.CLOSE} size={16} color={COLORS.WHITE} />
												</div>}
											{topRightToggle && onToggleClicked &&
												<div className="platform">
													<div className="field-group switch">
														<label>{toggleLabel}</label>
														<Switch
															on={toggleValue}
															value={toggleValue}
															onClick={onToggleClicked}
														/>
														<div className="tooltip">
															<FieldLabel
																label=" "
																required
																tooltip={[PrimaryTooltip, topRightToggleTooltipText]}
															/>
														</div>
													</div>
												</div>
											}
										</div>
										{closeable && (
											<button
												className="no-style close"
												onClick={onRequestClose}
											>
												<Icon
													name={ICONS.CLOSE}
													size={16}
													color={COLORS.WHITE}
												/>
											</button>
										)}
										{subtitle && <p className="modal-subtitle">{subtitle}</p>}
										{header}
									</div>
								)}
								<div
									ref={modalBodyRef}
									className={`modal-body ${showOverflow ? 'show-overflow' : ''} ${scrollbarVisible && "scrollbar-visible"}`}
									onScroll={handleBodyScroll}
								>{children}</div>
								{footer && <div className="modal-footer">{footer}</div>}
							</>
						</ModalBodyWrapper>
					</div>
				</div>
			</Trap>
		</ModalPortal>
	) : (
		<></>
	);
}
