import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { batch } from 'react-redux';
import { isObject } from 'underscore';
import classNames from 'classnames';
import { ColorResult } from 'react-color';

import { CreateNewColorPack, SaveColor } from '../../../connection/colors';
import { UploadFile } from '../../../connection/uploads';
import { useIsNewNavigation } from '../../../hooks/navigation.hooks';
import {
	addNewColorPack,
	saveColorPack,
	setDesignColors,
	updateNavbarBlurLevel
} from '../../../store/actions/admin/create-event';
import { useAppDispatch, useTypedSelector } from '../../../store/reducers/use-typed-selector';
import { ENavbarTypes } from '../../../types/working-model';
import { parseColor } from '../../../utils/document-head';
import CustomColorEditorModal from '../../admin/create/settings/design/colors/custom-color-editor-modal';
import LargeButton from '../button/large-button';
import Icon, { COLORS, ICONS } from '../icon';
import ImageEditor from '../image-editor/image-editor';
import ModalComponent from '../modal/modal';
import WaitingIndicator from '../waiting-indicator/waiting-indicator';
import ColorEditor from '../../admin/create/settings/design/colors/color-editor';
import { showAlert } from '../alert/alert-service';
import FileInput from '../../../utils/file-input';
import { OptionalComponent } from '../../../utils/optional-component';
import useClickAwayListener from 'utils/use-click-away-listener';
import {
	COLOR_VARIABLES_LIST_V2,
	EPaletteModes,
	ThemePack,
	ColorOptions,
	ColorValueIndexes,
	hex,
	opacity,
	TColorVariantKeys,
	UpdateThemePack,
} from 'types/theme-packs';
import { mutateV1ColorsToV2Colors } from 'utils/colors/mutate-v1-colors-to-v2-colors';
import { useGetColorOptionsFromColor } from 'utils/colors/use-get-color-options-from-color';
import ColorPicker from '@general-ui/color-picker-v2/color-picker/color-picker';

const LEFT_OFFSET = 20;
const HALF_COLOR_POPOVER_HEIGHT = 208;

type TColorValues = [TColorVariantKeys, [hex, opacity]][];
interface IThemePreviewProps {
	color?: [hex, opacity]; // color hex, opacity
	imgSrc?: string;
	isCurrent?: boolean;
	onClick: React.MouseEventHandler<HTMLButtonElement>;
	editable?: boolean;
	onColorChange?: (color: [hex, opacity]) => void;
	restoreDefaults?: boolean;
}

const ThemePreview: React.FC<IThemePreviewProps> = (props) => {
	const {
		color,
		imgSrc,
		isCurrent,
		onClick: handleClick,
		editable,
		onColorChange = () => null,
		restoreDefaults = false,
	} = props;

	const [showColorPicker, setShowColorPicker] = useState(false);
	const [colorState, setColorState] = useState(color);
	const [popoverX, setPopoverX] = useState(0);
	const [popoverY, setPopoverY] = useState(0);

	useEffect(() => {
		if (restoreDefaults && color) {
			setColorState(color);
		}
	}, [color, restoreDefaults]);

	const popperRef = useRef<HTMLDivElement | null>(null);

	useClickAwayListener({
		ref: popperRef,
		onClickAway: () => null,
		onMousedownClickAway: () => setShowColorPicker(false),
		onEscape: () => setShowColorPicker(false),
		open: showColorPicker
	});

	const handleColorChange = (selectedColor: ColorResult) => {
		const colorAlpha = Number(selectedColor.rgb.a || 1) || 1;
		setColorState([selectedColor.hex, colorAlpha]);
		onColorChange?.([selectedColor.hex, colorAlpha]);
	};

	// fixed positioning for color picker to beat z-index issues
	const handleTogglePicker = (e: React.MouseEvent) => {
		const modalParent = document.querySelector('.modal-wrapper.open .modal');
		const parentOffset = modalParent?.getBoundingClientRect();
		setPopoverX(e.clientX - (parentOffset?.left || 0) + LEFT_OFFSET);
		setPopoverY(e.clientY - (parentOffset?.top || 0) - HALF_COLOR_POPOVER_HEIGHT);
		setShowColorPicker(prev => !prev);
	};

	return (
		<div className={classNames('color-option-container', { editable })}>
			<button
				style={{
					backgroundColor: colorState ? parseColor(colorState) : "",
					backgroundImage: imgSrc ? `url(${imgSrc})` : "",
					width: '40px',
					height: '40px',
					borderRadius: '50%'
				}}
				className={`round color-option ${isCurrent ? 'selected' : ''}`}
				onClick={handleClick}
			></button>
			<OptionalComponent display={editable}>
				<>
					<button
						className="custom-color-edit-btn clear no-style round"
						onClick={handleTogglePicker}
					>
						<Icon name={ICONS.EDIT} size={16} color={COLORS.WHITE} />
					</button>

					{showColorPicker && (
						<div className="color-picker-popup-container" ref={popperRef}>
							<div
								className="color-picker-popup"
								style={{ left: popoverX, top: popoverY, position: 'fixed' }}
							>
								<ColorPicker
									onChangeComplete={handleColorChange}
									color={colorState}
								/>
							</div>
						</div>
					)}
				</>
			</OptionalComponent>
		</div>
	);
};

export enum EContentColorOverrideNames {
	start_time = 'Start Time',
	title = 'Title',
	description = 'Description',
	custom_heading = 'Custom Heading',
	modules = 'Modules',
	background = 'Background',
	navItems = 'Navigation',
	blLogo = 'Brandlive Logo',
	registration_page_stream_date = 'Registration page stream date',
	registration_page_speakers_lineup = 'Registration page speakers lineup',
}

export enum EModuleStyleOverrides {
	title = 'title',
	description = 'description',
	custom_heading = 'custom_heading',
	primary_button = 'primary_button',
	secondary_butto = 'secondary_button',
	dropdowns = 'dropdowns'
}


interface IEditSectionColorsModalProps {
	open: boolean;
	title: EContentColorOverrideNames;
	backgroundImage?: string;
	isEventPages?: boolean;
	onFinish: (theme: string, colorKey: string) => void;
	onClose: () => void;
	allowBackgroundImage?: boolean;
	initialSelectedColorVariable?: string;
}

const EditSectionColorsModal: React.FC<IEditSectionColorsModalProps> = (props) => {
	const {
		open,
		title,
		backgroundImage,
		isEventPages,
		onFinish: handleFinish,
		onClose: handleClose,
		allowBackgroundImage = true,
		initialSelectedColorVariable,
	} = props;

	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const user = useTypedSelector(state => state.AuthReducer.user);
	const colorPackV2FF = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags['streams.color_palettes']);

	const colors = mutateV1ColorsToV2Colors(workingEvent?.settings?.design?.colors?.colors);

	const dispatch = useAppDispatch();

	const colorPalette = useGetColorOptionsFromColor(workingEvent?.settings.design.colors);

	const [uploading, setUploading] = useState(false);
	const [topLevelColors, setTopLevelColorsColors] = useState(colorPalette);
	const [displayCustomColor, setDisplayCustomColor] = useState(false);
	const [displayAddImage, setDisplayAddImage] = useState(false);
	const [editing, setEditing] = useState<boolean>(false); // used to disable save while user is actively editing the image
	const [imageFile, setImageFile] = useState<string | undefined>(backgroundImage);
	const [resizedImage, setResizedImage] = useState<File | null>();
	const [colorChange, setColorChange] = useState<[string, string]>();
	const [customColor, setCustomColor] = useState<[string, number] | null>(null);
	const [openEditColorModal, setOpenEditColorModal] = useState<boolean>(false);
	const [editedThemeCustomColor, setEditedThemeCustomColor] = useState<Record<string, Record<string, [string, number]>>>({});
	const [restoreDefaults, setRestoreDefaults] = useState(false);

	const defaultBlurLevel = workingEvent?.settings?.event_navbar?.blurLevel;
	const [blurLevel, setBlurLevel] = useState(defaultBlurLevel || 0);

	const isNewNavigation = useIsNewNavigation();

	const navbarType = workingEvent?.settings?.event_navbar?.navbarType;
	const isTopNavbar = navbarType === ENavbarTypes.Horizontal;

	const colorTheme = workingEvent?.settings?.design?.color_theme ?? EPaletteModes.Light;

	const activeColor = workingEvent?.settings?.event_navbar?.styling_overrides?.background?.color.split('-')[0] as TColorVariantKeys | undefined;

	const color: [hex, opacity] | undefined = activeColor ? colors?.[colorTheme]?.[activeColor] || colorPalette?.[activeColor] : undefined;

	const imageRef = useRef<HTMLInputElement | null>(null);
	useEffect(() => {
		if (colorPalette) {
			setTopLevelColorsColors(colorPalette);
		}
	}, [colorPalette]);

	useEffect(() => {
		setImageFile(backgroundImage);
	}, [backgroundImage]);

	const customColors: Record<EPaletteModes, TColorValues> = useMemo(() => {
		const _customColors = filteredCustomColors(colors, 'keep');

		// if dark mode is missing a light mode color, add it to dark mode:
		// Check if _customColors.dark is missing any values from _customColors.light
		const missingCustomColors: Record<number, [TColorVariantKeys, [string, number]]> = {};
		_customColors.light.forEach((lightColor, idx) => {
			if (!_customColors.dark.find(darkColor => lightColor[0] === darkColor[0])) {
				missingCustomColors[idx] = lightColor;
			}
		});

		// Add missing colors to _customColors.dark at the same index as light
		// so that they render in the same order in the UI
		Object.entries(missingCustomColors).forEach(([index, lightColor]) => {
			_customColors.dark.splice(Number(index), 0, lightColor);
		});
		return _customColors;
	}, [colors]);

	async function addCustomColor() {
		if (!workingEvent?.settings?.design?.colors || !topLevelColors || !token) return;

		const allCustomColorByNumber = customColors?.[colorTheme]?.map(color => Number(color?.[0]?.replace(/\D/g, '')));

		const lastCustomColorNumber = (allCustomColorByNumber || []).reduce((a: number, c: number) => c > a ? c : a, 0);

		let workingColor: ThemePack = { ...workingEvent.settings.design.colors, colors };

		// get all custom colors
		const _customColors = filteredCustomColors(colors, 'keep');

		// realistically missing colors won't really happen, but there's always potential for it
		// so if any custom colors exist in light that don't exist in dark, add them to dark
		const missingCustomColors: Record<string, [string, number]> = {};
		_customColors.light.forEach(lightColor => {
			if (!_customColors.dark.find(darkColor => lightColor[0] === darkColor[0])) {
				missingCustomColors[lightColor[0]] = lightColor[1];
			}
		});

		if (customColor) {
			workingColor = {
				...workingColor,
				colors: {
					...workingColor.colors,
					// add custom color to top level for backwards compat
					[`customColor${lastCustomColorNumber + 1}`]: customColor,
					// we need to also add the custom color to both the light and dark modes
					// so that the is available for both modes
					...(workingColor?.colors?.light ? {
						light: {
							...workingColor.colors.light,
							[`customColor${lastCustomColorNumber + 1}`]: customColor
						}
					} : {}),
					...(workingColor?.colors?.dark ? {
						dark: {
							...workingColor.colors.dark,
							[`customColor${lastCustomColorNumber + 1}`]: customColor
						}
					} : {}),
				}
			};
		}

		const updatedPack: UpdateThemePack = {
			...workingColor,
			font_pack: workingColor.font_pack?.font_pack,
			theme_mode: colorTheme,
		};

		if (!Number(workingEvent?.settings.design.colors.channel)) {
			const newColor = await CreateNewColorPack(token, updatedPack);
			batch(() => {
				dispatch(addNewColorPack(newColor));
				dispatch(setDesignColors(newColor));
			});
		} else {
			await SaveColor(token, updatedPack);
			batch(() => {

				dispatch(setDesignColors(workingColor));
				dispatch(saveColorPack(workingColor));
			});
		}

		if (isNewNavigation && isEventPages) {
			if (isTopNavbar) {
				dispatch(updateNavbarBlurLevel(blurLevel));
				return;
			}

			dispatch(updateNavbarBlurLevel(0));
		}
	}

	const paletteColors: Record<string, TColorValues> = useMemo(() => {
		// if v2, then only display the v2 colors map
		// and then when saving, we need to apply the auto adjusted secondary bg and container colors
		const filtered = filteredCustomColors(colors, 'remove');

		if (colorPackV2FF) {
			const v2Keys = COLOR_VARIABLES_LIST_V2.map(color => color.name);
			// only display v2 color variables
			filtered.light = filtered.light.filter(color => v2Keys.includes(color[0]));
			filtered.dark = filtered.dark.filter(color => v2Keys.includes(color[0]));
		} else {
			// remove button text since it's a v2 variable
			filtered.light = filtered.light.filter(color => !color[0].includes('buttonText'));
			filtered.dark = filtered.dark.filter(color => !color[0].includes('buttonText'));
		}

		return filtered;
	}, [colorPackV2FF, colors]);

	const backgroundImgElement = useMemo(() => {
		if (!imageFile) return <></>;

		return (
			<ThemePreview
				key={imageFile}
				imgSrc={imageFile}
				onClick={() => {
					setColorChange(['image', imageFile]);
					setRestoreDefaults(false);
				}}
				isCurrent={colorChange?.[1] === imageFile}
			/>
		);
	}, [imageFile, setColorChange, colorChange]);

	const contentStylingOptions = useCallback((theme: EPaletteModes) => {
		return paletteColors?.[theme]?.map((color: [string, [hex, opacity]]) => {
			if (Array.isArray(color) && color[0] && color[1]) {
				return (
					<ThemePreview
						key={color[1][ColorValueIndexes.hex] + color[0]}
						color={color[1]}
						onClick={() => {
							setColorChange([theme, color[0]]);
							setRestoreDefaults(false);
						}}
						isCurrent={colorChange?.[1] === color[0] || (!colorChange && !restoreDefaults && initialSelectedColorVariable === color[0])}
					/>
				);
			}
		});
	}, [colorChange, initialSelectedColorVariable, paletteColors, restoreDefaults]);

	const customColorElements = useCallback((theme: EPaletteModes) => {
		return customColors?.[theme]?.map((color: [string, [string, number]], idx) => {
			if (Array.isArray(color) && color[0] && color[1]) {
				return (
					<ThemePreview
						key={color[1][ColorValueIndexes.hex]}
						color={color[1]}
						onClick={() => {
							setColorChange([theme, color[0]]);
							setRestoreDefaults(false);
						}}
						isCurrent={colorChange?.[1] === color[0] || (!colorChange && !restoreDefaults && initialSelectedColorVariable === color[0])}
						editable
						restoreDefaults={restoreDefaults}
						onColorChange={color => {
							const customColorVariableName = customColors?.[theme][idx][0];
							if (!customColorVariableName) return;

							setEditedThemeCustomColor(prev => {
								return {
									...prev,
									[theme]: {
										...prev[theme],
										[customColorVariableName]: color,
									}
								};
							});
						}}
					/>
				);
			}
		});
	}, [colorChange, customColors, initialSelectedColorVariable, restoreDefaults]);

	async function handleAddImage(f: File | FileList) {
		if (!user || !token) return;
		try {
			if (f instanceof File) {
				setUploading(true);
				setResizedImage(f);
				const uploadedImage = await UploadFile(user, token, f);
				setImageFile(uploadedImage);
				setUploading(false);
			}
		} catch (e: unknown) {
			console.error(e);
			setEditing(false);
			setUploading(false);
			setResizedImage(null);
			if (isObject(e) && e?.message) {
				showAlert({
					message: e.message,
					duration: 7000,
					type: "error"
				});
			}
		}
	}

	function handleRestoreDefault() {
		setRestoreDefaults(true);

		// if initialValue, find the associated color and set it
		if (initialSelectedColorVariable) {

			// if the currently active color is not a custom color, get the default
			// value so we can "select" it in the UI
			let matchingColor: string | undefined;
			if (!initialSelectedColorVariable.includes('customColor')) {
				matchingColor = paletteColors?.light?.find(color => color[0] === initialSelectedColorVariable)?.[0];
			}

			if (matchingColor) {
				setColorChange([EPaletteModes.Light, matchingColor]);
			} else {
				setColorChange(undefined);
			}
			setEditedThemeCustomColor({});
		}
	}

	const handleAddNewColor = () => {
		if (isNewNavigation && isTopNavbar && isEventPages) {
			setOpenEditColorModal(true);
			return;
		}

		setDisplayCustomColor(!displayCustomColor);
	};

	const workingStyleOptionMap = {
		background: (
			<div className={classNames("style-options", { 'show-overflow': title !== EContentColorOverrideNames.background })}>
				{
					displayAddImage ?
						imageFile ?
							<ImageEditor
								originalImage={imageFile}
								onFinished={setResizedImage}
								setEditing={setEditing}
							/>
							:
							<div className="upload-background-image">
								<LargeButton
									title={"Upload Image"}
									subtitle={"Use PNG or JPG files up to 10 Mb size"}
									allowedFileTypes={['image/png', 'image/jpeg', 'image/gif']}
									onFile={handleAddImage}
									style={{ marginTop: 40, marginBottom: 40, paddingTop: 36, paddingBottom: 36 }}
								/>
							</div>
						:
						<>
							<p>Background</p>
							<div className="options">
								<div>
									<div>
										<h6>Light</h6>
										{contentStylingOptions(EPaletteModes.Light)}
										<ColorDivider showDivider={!!customColors?.light?.length} />
										{customColorElements(EPaletteModes.Light)}
									</div>
									<div>
										<h6>Dark</h6>
										{contentStylingOptions(EPaletteModes.Dark)}
										<ColorDivider showDivider={!!customColors?.dark?.length} />
										{customColorElements(EPaletteModes.Dark)}
									</div>
								</div>
								<button
									className="small-button round"
									onClick={handleAddNewColor}
								>
									<Icon name={ICONS.ADD} size={16} color={COLORS.WHITE} />
								</button>
								{
									// for modules that shouldnt have background image
									allowBackgroundImage && (
										<>
											<p>or</p>
											{backgroundImgElement}
											<button
												className="small-button round"
												onClick={() => {
													setDisplayCustomColor(false);
													setDisplayAddImage(true);
												}}
											>
												<Icon name={ICONS.IMAGE} size={20} color={COLORS.WHITE} />
											</button>
										</>
									)
								}
							</div>
						</>
				}
			</div>),
		content: (
			<div className={classNames("style-options", { 'show-overflow': title !== EContentColorOverrideNames.background })}>
				<p>{title !== EContentColorOverrideNames.title && title}</p>
				<div className="options">
					<div>
						<div>
							<h6>Light</h6>
							{contentStylingOptions(EPaletteModes.Light)}
							<ColorDivider showDivider={!!customColors?.light?.length} />
							{customColorElements(EPaletteModes.Light)}
						</div>
						<div>
							<h6>Dark</h6>
							{contentStylingOptions(EPaletteModes.Dark)}
							<ColorDivider showDivider={!!customColors?.dark?.length} />
							{customColorElements(EPaletteModes.Dark)}
						</div>
					</div>
					<button onClick={() => setDisplayCustomColor(true)} className="small-button round">
						<Icon name={ICONS.ADD} size={16} color={COLORS.WHITE} />
					</button>
				</div>
			</div>
		),
	};

	const handleCloseModal = (): void => {
		setOpenEditColorModal(false);
		setCustomColor(null);
		setColorChange(undefined);
		setEditedThemeCustomColor({});
	};

	return (
		<>
			<ModalComponent
				cancellable={true}
				closeable={false}
				open={open}
				onRequestClose={handleClose}
				title={displayAddImage ? "Upload background photo" : "Customize Section Colors"}
				size={displayAddImage ? undefined : "large"}
				showOverflow={title !== EContentColorOverrideNames.background}
				footer={(
					<>
						{
							displayAddImage && imageFile ? (
								<>
									<button
										className="no-style replace-logo"
										onClick={() => {
											if (!uploading) { imageRef.current?.click(); }
										}}
									>
										<Icon
											name={ICONS.UPLOAD}
											color={COLORS.CYAN}
											size={12}
										/>
										Replace Photo
									</button>
									<FileInput
										type="file"
										accept={["image/jpeg", "image/png", "image/gif"]}
										onChange={(e) => handleAddImage((e.target.files?.[0] as File))}
										style={{ display: 'none' }}
										ref={imageRef}
									/>
								</>
							) : (
								<button
									className="no-style restore-default text-only-primary"
									onClick={handleRestoreDefault}
								>
									Restore default
								</button>
							)
						}
						<button
							style={{ marginRight: 16 }}
							onClick={() => {
								if (displayAddImage) {
									setDisplayAddImage(false);
									return;
								}
								handleClose();
							}}
						>
							Cancel
						</button>
						<button
							disabled={uploading || editing}
							className="lemonade"
							onClick={async () => {
								const hasEditedThemeCustomColors = Object.keys(editedThemeCustomColor?.light || {})?.length || Object.keys(editedThemeCustomColor?.dark || {})?.length;
								try {
									if (displayAddImage && resizedImage) {
										await handleAddImage(resizedImage);
										setDisplayAddImage(false);
										setEditing(false);
									} else {
										const _colorChange = colorChange?.[1];

										// if hasEditedThemeCustomColors, update the color palette to include the updated colors
										// before sending the color change back
										if (hasEditedThemeCustomColors && workingEvent && token) {
											let workingColor: ThemePack = { ...workingEvent.settings.design.colors };
											workingColor = {
												...workingColor,
												colors: {
													...workingColor.colors,
													...(workingColor?.colors?.light ? {
														light: {
															...workingColor.colors.light,
															...(editedThemeCustomColor?.light || {})
														}
													} : {}),
													...(workingColor?.colors?.dark ? {
														dark: {
															...workingColor.colors.dark,
															...(editedThemeCustomColor?.dark || {})
														}
													} : {}),
												}
											};

											batch(() => {
												dispatch(setDesignColors(workingColor));
												dispatch(saveColorPack(workingColor));
											});

											const updatedPack: UpdateThemePack = {
												...workingColor,
												font_pack: workingColor.font_pack?.font_pack,
												theme_mode: colorTheme,
											};

											await SaveColor(token, updatedPack);
										}

										// Checks to see if the string going back is a backgound URL
										// If so just, send that back, if not send back the full class override name
										const classOverride = title === EContentColorOverrideNames.background
											? _colorChange
											: _colorChange + "-color";

										if (restoreDefaults) {
											handleFinish(
												colorChange?.[0] || EPaletteModes.Light,
												'',
											);
										} else if (_colorChange && classOverride) {
											handleFinish(
												colorChange?.[0] || EPaletteModes.Light,
												_colorChange?.includes(".") ? _colorChange : classOverride
											);
										}

										handleClose();
									}
								} catch (e) {
									console.error(e);
									setEditing(false);
									setUploading(false);
									setImageFile(undefined);
								}
							}}
						>
							{
								uploading ?
									<WaitingIndicator />
									:
									'Done'
							}
						</button>
					</>
				)}
			>
				<div className="style-modal-content">
					{title === EContentColorOverrideNames.background ? workingStyleOptionMap.background : workingStyleOptionMap.content}

					<OptionalComponent display={(!isEventPages || isNewNavigation && !isTopNavbar || !isNewNavigation) && displayCustomColor}>
						<div className="custom-color-editor">
							<ColorEditor
								color={customColor ?? ['', 1]}
								title={"Custom Color"}
								onChange={(hex: string, opacity: number) => setCustomColor([hex, opacity])}
							/>
							<button
								className="small-button round"
								onClick={() => {
									addCustomColor();
									setDisplayCustomColor(false);
								}}
							>
								<Icon name={ICONS.ADD} size={16} color={COLORS.WHITE} />
							</button>
						</div>
					</OptionalComponent>
				</div>
			</ModalComponent>

			<OptionalComponent display={isNewNavigation && isTopNavbar}>
				<CustomColorEditorModal
					color={Array.isArray(color) ? color : ['', 1]}
					open={openEditColorModal}
					handleClose={handleCloseModal}
					setCustomColor={setCustomColor}
					addCustomColor={addCustomColor}
					blurLevel={blurLevel}
					setBlurLevel={setBlurLevel}
				/>
			</OptionalComponent>
		</>
	);
};

export default EditSectionColorsModal;

const ColorDivider = ({ showDivider }: { showDivider: boolean }) => {
	if (!showDivider) return null;
	return (
		<span className='color-icon-divider' />
	);
};

const filteredCustomColors = (
	colors: ColorOptions,
	filterType: 'keep' | 'remove'
): { light: TColorValues; dark: TColorValues; } => {

	const filterColor = (color: [string, [string, number] | undefined] | undefined) => {
		if (!color?.[0] || !color[1]) return false;
		if (filterType === 'keep') {
			return color[0].includes('customColor');
		}
		return !color[0].includes('customColor');
	};

	const { light, dark, ...topLevelColors } = colors;

	return {
		light: Object.entries(light ?? topLevelColors ?? {}).filter(filterColor) as TColorValues, // asserting because of undefined, but we are explicitly filtering out undefined values
		dark: Object.entries(dark ?? topLevelColors ?? {}).filter(filterColor) as TColorValues,	 // asserting because of undefined, but we are explicitly filtering out undefined values
	};
};
