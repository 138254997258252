import { EPermissions, PERMISSIONS } from "types/working-model";

export const rolePermissionSortOrder = {
	[PERMISSIONS.Owner]: 0,
	[PERMISSIONS.Admin]: 1,
	[PERMISSIONS.GroupAdmin]: 2,
	[PERMISSIONS.Editor]: 3,
	[PERMISSIONS.Analytics]: 4,
	[PERMISSIONS.Moderation]: 5,
	[PERMISSIONS.Producer]: 6,
	[PERMISSIONS.Viewer]: 7,
	[PERMISSIONS.PasscodeManager]: 8,
	[PERMISSIONS.ModeratorRegistrant]: 9,
	[PERMISSIONS.Builder]: 10,
	[PERMISSIONS.Presenter]: 11
};

export const allAccessRoles = [EPermissions.Owner, EPermissions.Admin, EPermissions.Builder];