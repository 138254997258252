import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';

import { VideoStateContext, Actions } from '../session-stream-provider';
import Icon, { ICONS, COLORS } from '../../../../general-ui/icon';
import ProgressTrack from './components/progress-track';
import PopoverWithNav, { PopoverWithNavOptions } from '../../../../general-ui/popover-with-nav/popover-with-nav';
import { OptionalComponent } from '../../../../../utils/optional-component';
import * as Signals from '../../../../../utils/event-emitter';
import useLive from '../../../../../utils/use-live';
import Reactions from './components/reactions';
import PageVisible from '../../../../../utils/page-visible-component';
import FloatingReaction from '../../../modules/reactions/floating-reaction';
import { BrandliveEvent, PageModuleType, ReactionConfig, SessionTypesEnum } from '../../../../../types/working-model';
import { TimeUpdate, VideoPlayerType } from './types';
import { Volume } from './components/volume';
import { getTemplateClassName } from '../../../../../utils/utils';
import { getCanDisplayCaptionsButton, useControlsHoverEffect } from './utils';
import { useTranslate } from '../../../../../i18n/useTranslationModules';
import { linker } from '../../../hooks/use-route-map';
import { useActiveModuleGroupsInSession, useIsAboveTheFold, useIsSingleSessionWithoutHome } from '../../../../../hooks/session.hooks';
import BufferingIndicator from '../../../../general-ui/buffering-indicator/buffering-indicator';
import { useAppDispatch, useTypedSelector } from '../../../../../store/reducers/use-typed-selector';
import HandRaiseButton from '../../fireside/raise-hand';
import Captions from './components/captions';
import NavigationToggle from '../../../marketing-page/navigation-v2/navigation-toggle';
import { Button } from '../../../../general-ui/button/button';
import useKeyboardShortcuts from './components/keyboard-shortcuts';
import LiveIndicator from './components/live-indicator';
import CurrentTimeIndicator from './components/current-time-indicator';
import ScrollForDetails from './components/scroll-for-details';
import { useScreenMediaQuery } from '../../../../../utils/use-screen-media-query';
import ActionBar from '../../session-modules/action-bar/action-bar';
import RevealableText from './components/revealable-text';
import { setEventNavOpen } from 'store/actions/event/event-actions';

export const ButtonIcon = (name: string, size = 20) => {
	return useMemo(() => (
		<Icon name={name} color={COLORS.WHITE} size={size} />
	), [name, size]);
};

const icon = ButtonIcon;

export const ControlButton: React.FC<{
	className?: string,
	onClick: () => void,
	onHover?: (e: React.MouseEvent<HTMLButtonElement>) => void,
	tooltip?: string,
	tooltipClass?: string,
	children: JSX.Element | JSX.Element[]
}> = ({ children, onClick, onHover, className, tooltip, tooltipClass }) => {
	return useMemo(() => (
		<div className="control-button-container">
			<button
				aria-label={tooltip}
				className={classNames("control-button", className)}
				onClick={e => {
					// This is to prevent from overfiring when the user has this 
					// button selected and presses the space bar
					if (e.detail === 1) {
						onClick();
					}
				}}
				onPointerEnter={(e) => onHover?.(e)}
			>
				{children}
			</button>
			{!!tooltip && <label className={tooltipClass}>{tooltip}</label>}
		</div>
	), [children, onClick, onHover, className, tooltip, tooltipClass]);
};

type ControlsProps = {
	onChatClose?: (closed: boolean) => void;
	isFullscreen: boolean;
	chatClosed: boolean;
	isPip: boolean;
	moderatorView?: boolean;
	event: BrandliveEvent;
	isAdmin: boolean;
	onPlayButtonClick?: () => void;
	playButtonClicked: boolean;
	showMeetRecording?: boolean;
}

enum SettingsKeys {
	PlaybackSpeed = 'Playback speed',
	Quality = 'Quality',
	Subtitles = 'Subtitles'
}

const Controls: React.FC<ControlsProps> = ({
	onChatClose,
	isFullscreen,
	chatClosed,
	moderatorView,
	event,
	isAdmin,
	onPlayButtonClick = () => null,
	playButtonClicked,
	showMeetRecording
}) => {
	const { state, dispatch } = useContext(VideoStateContext);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const videoMetadataRef = useRef<HTMLDivElement>(null);
	const videoMetadataContentRef = useRef<HTMLDivElement>(null);
	const {
		activeQuality,
		autoplayMuted,
		buffering,
		captions,
		chatIsOverlaid,
		durationInSeconds,
		hasIosCaptions,
		hasLiveCues,
		hasRecordedCues,
		manualPip: isPip,
		muted,
		overlayOpen,
		paused,
		pip,
		playbackRate,
		playbackRates,
		playerType,
		playing,
		qualities,
		quality,
		reactions,
		reactionsEnabled,
		selectedCaptions,
		session,
		showCaptions,
		useNativePip,
		playbackUrl
	} = state;
	const [settingsOpen, setSettingsOpen] = useState(false);
	const [reactionsOpen, setReactionsOpen] = useState(false);
	const [showFullscreen, setShowFullscreen] = useState(false);
	const [revealDescription, setRevealDescription] = useState(false);

	const template = getTemplateClassName(event.template.name);
	const { language } = useParams<{ language: string }>();
	const history = useHistory();
	const { t } = useTranslate(["session"]);
	const { isLessThan1024 } = useScreenMediaQuery();
	const isDesktop = !isLessThan1024;
	const editorSize = useTypedSelector(state => state.CreateEventReducer.editorSize);
	const firesidesHostSessions: number[] = useTypedSelector(state => state.LiveEventReducer.firesidesHostSessions);
	const fsJoined = useTypedSelector(state => state.FiresidesReducer.fsJoined);
	const isFireside = session?.session_type === SessionTypesEnum.fireside;
	const isFiresideHost = isFireside && firesidesHostSessions.includes(session?.session ?? -1);
	const singleSession = useIsSingleSessionWithoutHome();
	const appDispatch = useAppDispatch();
	useControlsHoverEffect(wrapperRef, playing);
	const isAboveTheFold = useIsAboveTheFold(session);
	const activeModuleGroups = useActiveModuleGroupsInSession(session, isAdmin);

	const isAboveTheFoldWithActiveModules = useMemo(() => {
		return isAboveTheFold && !!activeModuleGroups.length;
	}, [isAboveTheFold, activeModuleGroups]);

	const currentPlayedPercentageRef = useRef(0);
	const durationInSecondsRef = useRef(durationInSeconds);
	const lowerElementRef = useRef<HTMLDivElement | null>(null);

	// Moving the transform for .video-metadata from css to here beacuse we're basing the translate value of the parent off one of the children's changing height
	useEffect(() => {
		if (videoMetadataContentRef.current && videoMetadataRef.current && !revealDescription) {
			const transform = videoMetadataContentRef.current.clientHeight / videoMetadataRef.current.clientHeight * 100;
			videoMetadataRef.current.style.transform = `translateY(${transform}%)`;
		}

		if (videoMetadataRef.current && (revealDescription || !isDesktop || !(editorSize === 'desktop'))) {
			videoMetadataRef.current.style.transform = `translateY(0)`;
		}
	}, [revealDescription, isDesktop, editorSize]);


	useEffect(() => {
		const updateRefs = (progress: TimeUpdate) => {
			currentPlayedPercentageRef.current = progress.played;
		};

		Signals.addEventListener('video-player-progress', updateRefs);

		return () => {
			Signals.removeEventListener('video-player-progress', updateRefs);
		};
	}, []);

	useEffect(() => {
		durationInSecondsRef.current = durationInSeconds;
	}, [durationInSeconds]);

	const handleSetting = (key: string, option: string) => {
		switch (key) {
			case SettingsKeys.Quality: {
				const newQuality = qualities.find(q => q.label === option);
				if (newQuality) {
					dispatch({ type: Actions.SetQuality, payload: newQuality });
				}
				break;
			}

			case SettingsKeys.Subtitles: {
				const newCaption = captions.find(c => c.label === option);
				dispatch({ type: Actions.SetSelectedCaptions, payload: newCaption });
				break;
			}

			case SettingsKeys.PlaybackSpeed: {
				if (option === t('Normal')) {
					dispatch({ type: Actions.SetPlaybackRate, payload: 1 });
				} else {
					dispatch({ type: Actions.SetPlaybackRate, payload: Number(option) });
				}
				break;
			}

			default: {
				console.warn('Not yet implemented', key);
				break;
			}
		}
	};

	useEffect(() => {
		const handleFullscreenAvailable = (available: boolean) => {
			setShowFullscreen(available);
		};

		Signals.addEventListener('fullscreen-available', handleFullscreenAvailable);

		return () => {
			Signals.removeEventListener('fullscreen-available', handleFullscreenAvailable);
		};
	}, [dispatch]);

	// Only relevant for eCDN, which uses the HLS player
	const [broadcastIsLive] = useLive(playbackUrl, session);

	const isLive = playerType === VideoPlayerType.ivs || broadcastIsLive;
	const isMeetEmbed = playerType === VideoPlayerType.meet && !showMeetRecording;
	const isEmbed = playerType === VideoPlayerType.embed || isMeetEmbed;
	const isLink = playerType === VideoPlayerType.link;

	const [settingsIcon, qualityIcon] = useMemo(() => {
		const height = Number(activeQuality.label.replace('p', ''));
		const settingsIcon = (() => {
			if (height < 720) return ICONS.SETTINGS_SD;
			if (height >= 2100) return ICONS.SETTINGS_4K;

			return ICONS.SETTINGS_HD;
		})();
		const qualityIcon = (() => {
			if (height < 720) return ICONS.DEF_SD;
			if (height >= 2100) return ICONS.DEF_4K;

			return ICONS.DEF_HD;
		})();

		return [settingsIcon, qualityIcon] as [keyof typeof ICONS, keyof typeof ICONS];
	}, [activeQuality]);

	const settingsOptions = useMemo(() => {
		const options: PopoverWithNavOptions = {};

		if (playerType === VideoPlayerType.hls && !broadcastIsLive) {
			options[SettingsKeys.PlaybackSpeed] = {
				label: t('Playback speed'),
				icon: ICONS.FAST_FORWARD,
				selected: playbackRate === 1 ? t('Normal') : playbackRate.toLocaleString() + "x",
				options: playbackRates.map(rate => {
					if (rate === 1) return {
						label: t('Normal'),
						id: t('Normal')
					};
					return {
						label: rate.toString() + "x",
						id: rate.toString()
					};
				})
			};
		}

		if (qualities.length > 1) {
			options[SettingsKeys.Quality] = {
				label: t('Quality'),
				icon: ICONS.SETTINGS_SLIDERS,
				selected: quality.label,
				selectedIcon: qualityIcon,
				options: qualities.map(q => {
					return {
						label: q.label,
						id: q.label,
						icon: Number(q.label.replace('p', '')) >= 720 ? ICONS.DEF_HD as keyof typeof ICONS : undefined
					};
				}).sort((a, b) => Number(b.id.replace('p', '')) - Number(a.id.replace('p', ''))),
				subSelected: quality.label === 'Auto' ? <>{activeQuality.label} &nbsp;<Icon name={qualityIcon} size={12} color={COLORS.WHITE} /></> : undefined
			};
		}

		if (playerType === VideoPlayerType.hls && !!captions.length && !hasIosCaptions) {
			options[SettingsKeys.Subtitles] = {
				label: t('Subtitles'),
				icon: ICONS.CAPTIONS_OUTLINE,
				selected: selectedCaptions?.label ?? t('Off'),
				options: [{ label: t('Off'), id: t('Off') }, ...captions.map(c => ({ label: c.label, id: c.label }))]
			};
		}

		return options;
	}, [selectedCaptions, captions, qualities, quality, playerType, playbackRate, playbackRates, activeQuality, qualityIcon, t, hasIosCaptions]);

	const [date, time] = useMemo(() => {
		if (!session?.timestamp) return ['', ''];
		if (session?.session_type !== SessionTypesEnum.onDemand) {
			const date = new Date(session.timestamp);
			return [
				date.toLocaleDateString(navigator.language, { weekday: 'long', month: 'short', day: 'numeric' }),
				date.toLocaleTimeString(navigator.language, { timeStyle: 'short', hour12: true })
			];
		}

		return ['', ''];
	}, [session?.timestamp, session?.session_type]);

	const trackName = session?.tracks?.[0] ?? '';

	const description = useMemo(() => {
		const descriptionModule = session?.modules?.find(m => m.type === PageModuleType.description);
		if (descriptionModule?.is_on) {
			return descriptionModule.content.description?.[language] ?? descriptionModule.content.description?.base ?? '';
		}
	}, [session?.modules, language]);

	const showBack = !singleSession && !isFullscreen;
	const showHandRaise = session?.session_type === SessionTypesEnum.fireside && isLive && !isFiresideHost && !moderatorView;

	const hasTriggeredPlayAtLeastOnce = useRef(false);
	useEffect(() => {
		if (playing) {
			hasTriggeredPlayAtLeastOnce.current = true;
		}
	}, [playing]);

	const [
		handleBack,
		handleFullscreen,
		handlePlay,
		handlePause,
		handleBackTen,
		handleForwardTen,
		handleRestart,
		handleMute,
		handleUnmute,
		handleShowCaptions,
		handleHideCaptions,
		handleToggleSettings,
		handleToggleReactions,
		handleUnmuteAutoplay
	] = useMemo(() => {

		const handleBack = () => {
			history.push(linker.getLink('Home'));
		};

		const handleFullscreen = () => {
			Signals.broadcastSignal('toggle-fullscreen');
		};

		const handlePlay = () => {
			if (!playButtonClicked && !hasTriggeredPlayAtLeastOnce.current) {
				onPlayButtonClick();
			}
			dispatch({ type: Actions.Play, payload: undefined });
		};

		const handlePause = () => {
			dispatch({ type: Actions.Pause, payload: undefined });
		};

		const handleBackTen = () => {
			// need to know as a percentage of the whole how much 10 seconds is
			const percentPerSecond = (100 / durationInSecondsRef.current) / 100;
			const newPercentage = Math.max(0, currentPlayedPercentageRef.current - (percentPerSecond * 10));
			dispatch({ type: Actions.SeekTo, payload: newPercentage });
		};

		const handleForwardTen = () => {
			// need to know as a percentage of the whole how much 10 seconds is
			const percentPerSecond = (100 / durationInSecondsRef.current) / 100;
			const newPercentage = Math.min(1, currentPlayedPercentageRef.current + (percentPerSecond * 10));
			dispatch({ type: Actions.SeekTo, payload: newPercentage });
		};

		const handleRestart = () => {
			dispatch({ type: Actions.SeekTo, payload: 0 });
		};

		const handleMute = () => {
			dispatch({ type: Actions.SetMuted, payload: true });
		};

		const handleUnmute = () => {
			dispatch({ type: Actions.SetMuted, payload: false });
		};

		const handleShowCaptions = () => {
			dispatch({ type: Actions.EnableCaptions, payload: undefined });
		};

		const handleHideCaptions = () => {
			dispatch({ type: Actions.DisableCaptions, payload: undefined });
		};

		const handleToggleSettings = () => {
			setSettingsOpen(open => !open);
		};

		const handleToggleReactions = () => {
			setReactionsOpen(open => !open);
		};

		const handleUnmuteAutoplay = () => {
			dispatch({ type: Actions.UnmuteFromAutoplay, payload: undefined });
		};

		return [
			handleBack,
			handleFullscreen,
			handlePlay,
			handlePause,
			handleBackTen,
			handleForwardTen,
			handleRestart,
			handleMute,
			handleUnmute,
			handleShowCaptions,
			handleHideCaptions,
			handleToggleSettings,
			handleToggleReactions,
			handleUnmuteAutoplay
		];
	}, [history, dispatch, playButtonClicked, onPlayButtonClick]);

	useKeyboardShortcuts(handleBackTen, handleForwardTen);

	const togglePip = useCallback(() => {
		if (useNativePip) {
			dispatch({ type: Actions.SetPip, payload: !pip });
		} else {
			dispatch({ type: Actions.CancelPip, payload: true });
		}
		window.scroll({ top: 0, behavior: 'smooth' });
	}, [dispatch, pip, useNativePip]);

	const handleLinkOverlayClick = useCallback(() => {
		if (!playButtonClicked) {
			onPlayButtonClick();
		}

		if (playing) {
			handlePause();
		} else {
			handlePlay();
		}
	}, [handlePause, handlePlay, onPlayButtonClick, playButtonClicked, playing]);

	return (
		<>
			{isMeetEmbed && (
				<div
					className="meet-embed-nav-trigger"
					onClick={() => appDispatch(setEventNavOpen(true))}
				/>
			)}
			<div
				className={classNames("video-controls-wrapper hover", { buffering, paused, "overlay-opened": overlayOpen })}
				ref={wrapperRef}
			>
				{/* Buffering View */}
				{buffering && (
					<div className={"buffering-view"}>
						<BufferingIndicator />
					</div>
				)}

				{/* Top Controls */}
				<div className={classNames("upper", { 'link-player': playerType === VideoPlayerType.link })}>
					{/* Replaces the session header when in use */}
					<div className="left">
						<OptionalComponent display={!isFullscreen}>
							{/* Marketing Nav Button Opener */}
							<NavigationToggle
								forceOn={!moderatorView}
								hideToggle={!!moderatorView}
								isDesktop={isDesktop} // Forces to not show on NON desktop views
							/>

							{/* Back Button */}
							<OptionalComponent display={showBack && /*!isLessThan1024 && */ !moderatorView && !isMeetEmbed}>
								<ControlButton
									onClick={handleBack}
									tooltip={t('Go back', 'Go back')}
									tooltipClass='under'
								>
									{icon(ICONS.ARROW_LEFT_SHADOW)}
								</ControlButton>
							</OptionalComponent>
						</OptionalComponent>
					</div>
					<div className="right">
						{/* 
						
						// TODO implementation see: https://developers.google.com/cast/docs/reference
						// AIRPLAY: https://developer.apple.com/documentation/webkitjs/adding_an_airplay_button_to_your_safari_media_controls

						<div className="cast-container">
							<ControlButton onClick={doVoid}>
								{icon(ICONS.CHROMECAST)}
							</ControlButton>
						</div> 
						
						*/}

						{/* Picture in picture button */}
						<OptionalComponent display={isPip || useNativePip}>
							<div className="control pip-container">
								<ControlButton
									tooltip={t('Picture in Picture')}
									tooltipClass='under'
									className="pip-toggle"
									onClick={togglePip}
								>
									{icon(pip || isPip ? ICONS.PIP_EXIT : ICONS.PIP)}
								</ControlButton>
							</div>
						</OptionalComponent>

						{/* Fullscreen button */}
						<OptionalComponent display={showFullscreen}>
							<div className="control fullscreen-container">
								<ControlButton
									tooltip={t(isFullscreen ? 'Minimize' : 'Full screen')}
									tooltipClass='under'
									onClick={handleFullscreen}>
									{icon(isFullscreen ? ICONS.FULLSCREEN_SQUARE_EXIT : ICONS.FULLSCREEN_SQUARE)}
								</ControlButton>
							</div>
						</OptionalComponent>
					</div>
				</div>

				<OptionalComponent display={playerType === VideoPlayerType.link}>
					<div
						onClick={handleLinkOverlayClick}
						className="center-control link-player"
					/>
				</OptionalComponent>

				{/* Lower Controls */}
				<div className={classNames("lower", { 'link-player': playerType === VideoPlayerType.link })} ref={lowerElementRef}>
					{/* Session title/description - hidden for embedded iframes */}
					<OptionalComponent display={!isEmbed && !isPip}>
						<div
							className={classNames("video-metadata", { revealed: revealDescription })}
							onClick={() => setRevealDescription(!revealDescription)}
							ref={videoMetadataRef}
						>
							<div className="video-time-row">
								{date && <span className="video-date">{date}</span>}
								{(date && time) && <span className="video-separator">•</span>}
								{time && <span className="video-time">{time}</span>}
								{(time && trackName) && <span className="video-separator">•</span>}
								{trackName && <span className="video-track">{trackName}</span>}
							</div>
							{!moderatorView && (
								<div className={"video-title"}>
									<h4>
										{session?.title[language] ?? session?.title.base ?? ''}
									</h4>
									<button
										className={classNames("reveal-description", { revealed: !revealDescription })}
										// even though we have a click handler on the parent, we will keep this around for accessibility because it's a button element
										onClick={() => {
											setRevealDescription(!revealDescription);
										}}
									>
										<Icon name={ICONS.KEYBOARD_ARROW_DOWN} size={12} color={COLORS.WHITE} />
									</button>
								</div>
							)}

							{(!isLessThan1024 && !moderatorView) && (
								<div className={classNames('video-meta-content', { revealed: revealDescription })}
									ref={videoMetadataContentRef}
								>
									<RevealableText
										text={description}
										revealed={true}
										className="video-meta-description"
										onExpand={() => Signals.broadcastSignal('session-details-modal:open')}
									/>
									{(session && event) && (
										<ActionBar
											session={session}
											event={event}
											template={template}
											className="video-meta-actions"
											forceDark={true}
											isFullscreen={isFullscreen}
											includeJoinStage
										/>
									)}
								</div>
							)}
						</div>
					</OptionalComponent>

					{/* Progress track - hidden for embedded iframes */}
					<div className="video-control-actions">

						{/* Usable progress track - only for recorded/uploaded HLS videos, not live */}
						<OptionalComponent display={!isEmbed && !isLive}>
							<ProgressTrack wrapperRef={wrapperRef.current} />
						</OptionalComponent>

						{/* Mock progress track - just a white line for live videos */}
						<OptionalComponent display={!isEmbed && isLive}>
							<div className="progress track-gutter-container live">
								<div className="track-gutter" />
							</div>
						</OptionalComponent>

						{/* Bottom controls */}
						<div className="buttons-row">
							{/* Left side of lower controls. Play/pause/seek actions - hidden for embedded iframes */}
							<OptionalComponent display={!isEmbed}>
								<>
									<div className="left">
										<div className="control play-pause-container">
											<ControlButton
												tooltip={t(state.playing ? 'Pause' : 'Play')}
												onClick={state.playing ? handlePause : handlePlay}
												className='play-pause-toggle'
											>
												{icon(state.playing ? ICONS.PAUSE_SHADOW : ICONS.PLAY_SHADOW, 18)}
											</ControlButton>
										</div>

										<OptionalComponent display={!isLive}>
											<div className="control seek-to-beginning-container">
												<ControlButton
													tooltip={t('Restart')}
													onClick={handleRestart}>
													{icon(ICONS.SEEK_START)}
												</ControlButton>
											</div>
										</OptionalComponent>

										<OptionalComponent display={!isLive}>
											<div className="control seek-back-ten-container">
												<ControlButton
													tooltip={t('10 sec back')}
													onClick={handleBackTen}
												>
													{icon(ICONS.BACK_TEN)}
												</ControlButton>
											</div>
										</OptionalComponent>

										<OptionalComponent display={!isLive}>
											<div className="control seek-forward-ten-container">
												<ControlButton
													tooltip={t('10 sec forward')}
													onClick={handleForwardTen}
												>
													{icon(ICONS.FWD_TEN)}
												</ControlButton>
											</div>
										</OptionalComponent>

										<div className={classNames("control volume-container", { muted })}>
											<Volume />
											<ControlButton
												tooltip={t(muted ? 'Unmute' : 'Mute')}
												className='mute-toggle'
												onClick={muted ? handleUnmute : handleMute}
											>
												{icon(muted ? ICONS.MUTED_SHADOW : ICONS.MUTE_SHADOW, 16)}
											</ControlButton>
										</div>

										<LiveIndicator />

										<CurrentTimeIndicator />
									</div>
								</>
							</OptionalComponent>

							<OptionalComponent display={isEmbed}>
								<div className="left" />
							</OptionalComponent>

							{/* Right side of lower controls. Right side controls - visible for iframes */}
							<div className="right">

								{/* 
								// TODO: implement clipping - should hide from iframes and links (and live?)
								<div className="clip-container">
									<ControlButton onClick={doVoid}>
										{icon(ICONS.SCISSORS)}
									</ControlButton>
								</div> 
								*/}


								{/* 
								// TODO: implement transcript - should hide from iframes and links
								<div className="transcript-container">
									<ControlButton onClick={doVoid}>
										{icon(ICONS.TRANSCRIPT)}
									</ControlButton>
								</div> 
								*/}

								{/* Captions button - hidden from iframes at all times, visble in recording/uploaded/live only when captions are available */}
								<OptionalComponent display={getCanDisplayCaptionsButton({
									playerType,
									hasLiveCues,
									hasIosCaptions,
									hasRecordedCues,
									selectedCaptions,
									manualPip: isPip
								})}>
									<div className="control captions-enable-container">
										<ControlButton
											tooltip={t('Captions')}
											className={showCaptions ? "active" : ""}
											onClick={showCaptions ? handleHideCaptions : handleShowCaptions}>
											{icon(ICONS.CAPTIONS_OUTLINE)}
										</ControlButton>
									</div>
								</OptionalComponent>

								{/* Settings button - hidden from iframes and links at all times, visible in recording/uploaded/live */}
								<OptionalComponent display={!isEmbed && !isLink && !!Object.keys(settingsOptions).length}>
									<div className="control settings-container">
										<ControlButton
											tooltip={t('Settings')}
											className={settingsOpen ? "active" : ""} onClick={handleToggleSettings}>
											<div className="idle-icon">
												{icon(settingsIcon)}
											</div>
											<div className="active-icon">
												{icon(ICONS.SETTINGS_NO_SHADOW)}
											</div>
										</ControlButton>
										<PopoverWithNav
											open={settingsOpen}
											onSelected={handleSetting}
											onRequestClose={() => setSettingsOpen(false)}
											pages={settingsOptions}
											isFullscreen={isFullscreen}
										/>
										<div className="settings-button-notification">
										</div>
									</div>
								</OptionalComponent>

								{/* Reactions button - visible in all types when feature enabled */}
								<OptionalComponent display={reactionsEnabled && !!reactions.length}>
									<div className="control reactions-container">
										<ControlButton
											tooltip={t('Reactions')}
											className={reactionsOpen ? "active" : ""} onClick={handleToggleReactions}>
											{icon(ICONS.REACTION_ADD)}
										</ControlButton>
									</div>
								</OptionalComponent>

								{/* Hand Raise button - visible in fireside live sessions when user is not host */}
								{session && (
									<OptionalComponent display={showHandRaise}>
										<HandRaiseButton session={session} disableJoinStage={false} />
									</OptionalComponent>
								)}

								<OptionalComponent display={isEmbed}>
									<div
										className="description-modal-button-for-embeded-videos"
										onClick={() => Signals.broadcastSignal('session-details-modal:open')}
									>
										{icon(ICONS.INFO_OUTLINE)}
									</div>
								</OptionalComponent>

								{/* Chat button - only hidden when session_chat_enabled is defined and set to false */}
								<OptionalComponent display={
									(session?.session_chat_enabled !== false || isAboveTheFoldWithActiveModules)
									&& !isFullscreen
									&& !moderatorView}>
									<div className="control chat-container">
										<ControlButton
											tooltip={t('Chat')}
											onClick={() => {
												onChatClose?.(!chatClosed);
											}}
										>
											<OptionalComponent display={session?.session_chat_enabled}>
												{icon(chatClosed ? ICONS.CHAT_DIALOG : ICONS.CHAT_DIALOG_EXIT)}
											</OptionalComponent>
											<OptionalComponent display={!session?.session_chat_enabled && isAboveTheFoldWithActiveModules}>
												{icon(chatClosed ? ICONS.DOUBLE_ARROW_LEFT : ICONS.DOUBLE_ARROW_RIGHT)}
											</OptionalComponent>
										</ControlButton>
									</div>
								</OptionalComponent>
							</div>
						</div>
					</div>
				</div>

				{!moderatorView && (
					<ScrollForDetails />
				)}

				{/* Mobile Controls - separate for z-index layering as they appear in a totally separate part of the player */}
				<OptionalComponent display={!isEmbed}>
					<div className={classNames("mobile-controls", { hide: settingsOpen })}>
						<OptionalComponent display={!isLive}>
							<div className="control seek-back-ten-container">
								<ControlButton
									tooltip={t('10 sec back')}
									onClick={handleBackTen}
								>
									{icon(ICONS.BACK_TEN, 24)}
								</ControlButton>
							</div>
						</OptionalComponent>
						<div className="control play-pause-container">
							<ControlButton
								tooltip={t(state.playing ? 'Pause' : 'Play')}
								onClick={state.playing ? handlePause : handlePlay}
							>
								{icon(state.playing ? ICONS.PAUSE_SHADOW : ICONS.PLAY_SHADOW, 40)}
							</ControlButton>
						</div>
						<OptionalComponent display={!isLive}>
							<div className="control seek-forward-ten-container">
								<ControlButton
									tooltip={t('10 sec forward')}
									onClick={handleForwardTen}
								>
									{icon(ICONS.FWD_TEN, 24)}
								</ControlButton>
							</div>
						</OptionalComponent>
					</div>
				</OptionalComponent>
			</div>

			<OptionalComponent display={reactionsEnabled && !!reactions.length}>
				<Reactions open={reactionsOpen} onRequestClose={() => setReactionsOpen(false)} />
			</OptionalComponent>

			{/* Floating Reactions - displays the incoming reactions */}
			<OptionalComponent display={!!(session?.uuid && reactionsEnabled && reactions.length)}>
				<PageVisible>
					<FloatingReaction
						chatOverlayEnabled={chatIsOverlaid && !chatClosed}
						enabledReactions={reactions as ReactionConfig[]}
						sessionUuid={session?.uuid as string}
					/>
				</PageVisible>
			</OptionalComponent>

			<OptionalComponent display={autoplayMuted}>
				<div className="unmute-autoplay" onClick={handleUnmuteAutoplay}>
					<Button
						classButton="no-style clear"
						ariaLabel={t('Click to unmute', 'Click to unmute')}
						template={template}
					>
						{icon(ICONS.MUTED_SHADOW, 12)} <span>Unmute</span>
					</Button>
				</div>
			</OptionalComponent>

			<Captions />
		</>
	);
};

export default Controls;
